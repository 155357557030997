import React, { useEffect, useState } from 'react'
import bgimg from './assets/img/bg-home.jpg';
import horoscope from './assets/img/horoscope.png';
import logo from './assets/img/logo.png';
import Box from '@mui/material/Box';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { login } from './reduxFeature/user';
import TextField from '@mui/material/TextField';
import AccountCircle from '@mui/icons-material/AccountCircle';
import KeyIcon from '@mui/icons-material/Key';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import { adminLogin } from './AllComponents/Services/CommonApi';

const Login = ({ setIsUser }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [username, setUsername] = useState('')
    const [password, setPassword] = useState("")
    const [isValid, setIsValid] = useState(true)

    const handleEmail = (e) => {
        setUsername(e.target.value)
        setIsValid(true)
      }

      const handlePassword = (e) => {
        setPassword(e.target.value)
        setIsValid(true)
      }

    const stylebg = {
        backgroundImage: `url(${bgimg})`,
        backgroundAttachment: "fixed",
        backgroundRepeat: "noRepeat",
        backgroundPosition: "center",
        backgroundSize: "cover",
        height: "100vh",
        display: "block",
        color: "#fff",
        overflow: "hidden"
    }

    const loginuser = (e) => {
        e.preventDefault();

        if (username === '' || password === '') {
            setIsValid(false)
            return
          }

        if (username !== "" && password !== "") {

            const logObj = {
                username: username,
                password: password,
              }

            axios.post(adminLogin,logObj)
                .then((res) => {
                    if (res.data == "NOTFOUND") {
                        toast.error("Username and Password not valid");
                    }
                    else {
                        const token = res.data.token
                        axios.defaults.headers.common['token'] = token

                        const userLoginData = {
                            roleId: res.data.user.id,
                            userName: res.data.user.username
                        }
                        dispatch(login({ roleId: res.data.user.id, userName: res.data.user.username }));
                        sessionStorage.setItem("techno-muhrta", JSON.stringify(userLoginData));
                        sessionStorage.setItem('token',res.data.token)
                        setIsUser(true)
                        navigate("/dashboard/")
                    }
                })
                .catch((err) => {
                    console.log(err)
                    
                    toast.error('Server Error !!', {
                        position: "top-right",
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                      });
                })

            // if(username==="admin" && password==="123"){
            //                 const userLoginData = {
            //                 roleId: 1,
            //                 email: username
            //             }
            //             dispatch(login(userLoginData));
            //             sessionStorage.setItem("techno-muhrta", JSON.stringify(userLoginData));

            //             setIsUser(true)
            //             navigate("/dashboard/")
            // }

        }
        else {
            toast.error("Fields must not be empty.");
        }

    }

    useEffect(() => {

        const getData = sessionStorage.getItem("techno-muhrta");
        if (getData) {
            const localUserData = (JSON.parse(getData));
    
            dispatch(login({ roleId: localUserData.roleId, userName: localUserData.userName, }));
            //setIsUser(true);
            //navigate("/");    
        }
    }, []);

    const handleShowContactDetailsPage =()=>{
        navigate('/contactDetails');
    }
    const handleShowTermConditionPage =()=>{
        navigate('/termsCondition');
    }
    const handleShowPrivacyPolicyPage =()=>{
        navigate('/privacyPolicy');
    }
    const handleShowCancellationRefundPage =()=>{
        navigate('/cancellationRefund');
    }
    const handleShowShippingDeliveryPage =()=>{
        navigate('/shippingDelivery');
    }

    return (
        <>
            <div style={stylebg} className="wrapper-login">
                <div className="row m-0">

                           
                    <div className="col-sm-6 login-panel">
                        <div className="container">
                            <div>
                                <img src={logo} className="img-fluid" alt="" style={{ pointerEvents: "none", userSelect: "none" }} />
                            </div>
                            <div className="login">
                                <div className='col-sm-8 offset-sm-3' style={{ textAlign: "left" }}>
                                    <form onSubmit={loginuser}>
                                        <Box sx={{ alignItems: 'flex-end', color: "#fff" }}>
                                            <AccountCircle sx={{ color: '#fff', mr: 1, mt: 4 }} />
                                            <TextField
                                                margin="dense"
                                                required
                                                name='username'
                                                autoComplete='off'
                                                InputLabelProps={{ style: { color: "#fff", fontSize: "13px" } }}
                                                InputProps={{ style: { color: "#fff" } }}
                                                sx={{ color: "#fff", borderBottom: "1px dotted #fff", width: "80%" }}
                                                id="input-with-sx1" label="User Name"
                                                variant="standard"
                                                className='text-light'
                                                value={username}
                                                // onChange={(e) => setUsername(e.target.value)}
                                                onChange={handleEmail}
                                            />
                                        </Box>
                                        <Box sx={{ alignItems: 'flex-end', color: "#000" }}>
                                            <KeyIcon sx={{ color: '#fff', mr: 1, mt: 4 }} />
                                            <TextField
                                                margin="dense"
                                                autoComplete='off'
                                                required
                                                name='password'
                                                type='password'
                                                InputLabelProps={{ style: { color: "#fff", fontSize: "13px" } }}
                                                InputProps={{ style: { color: "#fff" } }}
                                                sx={{ color: "#fff", borderBottom: "1px dotted #fff", width: "80%" }}
                                                id="input-with-sx2" label="Password"
                                                variant="standard"
                                                value={password}
                                                // onChange={(e) => setPassword(e.target.value)}
                                                onChange={handlePassword}
                                            />
                                        </Box>
                                        <button type='submit' className='loginBtn' variant="outlined">LOGIN</button>
                                    </form>
                                    <div className='text-light mt-5' style={{ fontSize: "12px", letterSpacing: "1px" }}>
                                        Managed By: TechnoAarv Solution | &copy; 2025-26
                                    </div>
                                    {/* <div className="row border text-center my-2">
                                        <div className='col-12 col-lg-4 p-0'>
                                            <span style={{color:"white",fontSize:"12px"}}><i class="fa-solid fa-circle-dot"></i></span> &nbsp; <span style={{cursor:"pointer"}} onClick={handleShowContactDetailsPage}>Contact Details</span>
                                        </div>
                                        <div className='col-12 col-lg-4 p-0'>
                                            <span style={{color:"white",fontSize:"12px"}}><i class="fa-solid fa-circle-dot"></i></span> &nbsp; <span style={{cursor:"pointer"}} onClick={handleShowPrivacyPolicyPage}>Privacy Policy</span>
                                        </div>
                                        <div className='col-12 col-lg-4 p-0'>
                                            <span style={{color:"white",fontSize:"12px"}}><i class="fa-solid fa-circle-dot"></i></span> &nbsp; <span style={{cursor:"pointer"}} onClick={handleShowTermConditionPage}>Terms & Condition</span>
                                        </div>
                                    </div> */}
                                    <div className="col-12 my-2 mt-5">
                                    <div className="row m-0 text-start my-2">
                                        <div className='col-12  p-0'></div>
                                        <div className='col-12 col-md-6 p-0'>
                                            <span style={{color:"white",fontSize:"12px"}}><i class="fa-solid fa-circle-dot"></i></span> &nbsp; <span style={{cursor:"pointer"}} onClick={handleShowContactDetailsPage}>Contact Details</span>
                                        </div>
                                        <div className='col-12 col-md-6 p-0'>
                                            <span style={{color:"white",fontSize:"12px"}}><i class="fa-solid fa-circle-dot"></i></span> &nbsp; <span style={{cursor:"pointer"}} onClick={handleShowPrivacyPolicyPage}>Privacy Policy</span>
                                        </div>
                                        <div className='col-12 col-md-6 p-0'>
                                            <span style={{color:"white",fontSize:"12px"}}><i class="fa-solid fa-circle-dot"></i></span> &nbsp; <span style={{cursor:"pointer"}} onClick={handleShowTermConditionPage}>Terms & Condition</span>
                                        </div>
                                        <div className='col-12 col-md-6 p-0'>
                                            <span style={{color:"white",fontSize:"12px"}}><i class="fa-solid fa-circle-dot"></i></span> &nbsp; <span style={{cursor:"pointer"}} onClick={handleShowCancellationRefundPage}>Cancellation and Refund</span>
                                        </div>
                                        <div className='col-12 col-md-6 p-0'>
                                            <span style={{color:"white",fontSize:"12px"}}><i class="fa-solid fa-circle-dot"></i></span> &nbsp; <span style={{cursor:"pointer"}} onClick={handleShowShippingDeliveryPage}>Shipping and Delivery</span>
                                        </div>
                                        <div className='col-12 p-0'></div>
                                    </div>
                                    </div>

                                </div>
                            </div>
                        </div>

                    </div>

                    <div className="col-sm-6 horoscope">
                        <img src={horoscope} id="horoscopeimg" />
                    </div>
                </div>
            </div>
            <ToastContainer theme='colored' />
        </>
    )
}

export default Login