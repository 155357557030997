import React from 'react'
import logo from '../assets/img/logo.png';
import { useNavigate } from 'react-router-dom';

const ContactDetails = () => {

   const navigate = useNavigate();

  return (
    <>
    <div className='container-fluid' style={{height:"100vh", backgroundColor: 'grey'}}>
      <div className='row m-0 pt-3 mb-5'>

          <div className='col-12 text-center'>
            <img src={logo} className="img-fluid" alt="" style={{ pointerEvents: "none", userSelect: "none",width:"30%",height:"60%" }} />
          </div>

                <div className='row m-0 text-end '>
                  <div className='col-md-3 col-lg-4 '></div>
                  <div className='col-md-6 col-lg-4  py-3  text-end'>
                    <button className='py-1 px-2 rounded shadow'  onClick={() => navigate('/')} style={{border:"none",backgroundColor:"#bb4495",color:"white",}}>Back To Home</button>
                  </div>
                  <div className='col-md-3 col-lg-4 '></div>
                </div>
        


        <div className='col-12 col-md-3 col-lg-4'></div>
        <div className='col-12 col-md-6 col-lg-4 p-1 border shadow' style={{backgroundColor:"white"}}>
          <div className='row m-0 p-3'>
              <div className='col-12 py-3'>
                <h2 className='text-center'>Contact Us</h2>
              </div>
              <div className='col-12 py-2'><span className='fw-bold'>Address :</span> Shri Sadashiv Jyotish Karyalay Gavali Galli , Peth Bhag, Sangli 416416 Maharshatra. </div>
              <div className='col-12 py-2'><span className='fw-bold'>Email Id :</span> yelavikar@gmail.com</div>
              <div className='col-12 py-2'><span className='fw-bold'>Contact No. :</span> 7276766001</div>
          </div>
        </div>
        <div className='col-12 col-md-3 col-lg-4'></div>

      </div>
    </div>
    </>
  )
}

export default ContactDetails