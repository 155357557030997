import { BaseIP } from "./CommonApi"

export const getPartyMasterList1= BaseIP+ "muhurt/master/getPartyMasterList" //Get Party Master list (GET)
export const getCityMasterList1= BaseIP+ "muhurt/master/getCityMasterList" //Get City Master list (GET)
export const getCountryMasterList1= BaseIP+ "muhurt/master/getCountryMasterList" //Get Country Master list (GET)
export const getStateMasterList1= BaseIP+ "muhurt/master/getStateListByCountryId" //Get State Master list (GET) (params=> ?countryId)
export const getDistrictMasterList1= BaseIP+ "muhurt/master/getDistrictListByStateId" //Get District Master list (GET) (params=> ?stateId)
export const getTypeMasterList1= BaseIP+ "muhurt/master/getTypeMasterList" //Get Type Master list (GET)
export const getModelMasterList1= BaseIP+ "muhurt/master/getModelMasterList" //Get Model Master list (GET)
export const getWeekDaysMasterList1= BaseIP+ "muhurt/master/getWeekDaysMasterList" //Get Model Master list (GET)



export const savePatrikaEntry= BaseIP+ "muhurt/transaction/savePatrikaEntry" //Save Patrika Entry (POST)
export const updatePatrikaEntry= BaseIP+ "muhurt/transaction/updatePatrikaEntry" //Update Patrika Entry (PUT)
export const deletePatrikaEntry= BaseIP+ "muhurt/transaction/deletePatrikaEntry" //Delete Patrika Entry (DELETE) (?regNo=2)

export const getPatrikaEntryByRegNo= BaseIP+ "muhurt/transaction/getPatrikaEntryByRegNo" //Get Patrika Entry By regNo (GET) (?regNo=2)
export const getPatrikaEntryList= BaseIP+ "muhurt/transaction/getPatrikaEntryList" //Get Patrika Entry list (GET)



//===============================API For patrika created Page ====================================
export const getPatrikaEntryCreated= BaseIP+ "muhurt/transaction/getPatrikaListForCreated" //Get Patrika Entry created list (GET)
export const getPatrikaEntryCreatedSave= BaseIP+ "muhurt/transaction/savePatrikaListForCreated" //Get Patrika Entry created save (Post)(by params=> regNo=2 & date=2024-02-14)


//===============================API For patrika delivered Page ====================================
export const getPatrikaEntryDelivered= BaseIP+ "muhurt/transaction/getPatrikaListForDelivered" //Get Patrika Entry delivered list (GET)

export const getPatrikaEntryDeliveredSave= BaseIP+ "muhurt/transaction/savePatrikaListForDelivered" //Get Patrika Entry created save (Post)(by params=> regNo=2 & date=2024-02-14)


//============================================================================================================================
//============================================================================================================================
//============================================================================================================================
//============================================================================================================================
//===============================API For ONLINE Patrika Entry ====================================

export const getOnlinePatrikaEntryList = BaseIP+ "muhurt/transaction/getPatrikaEntryListOnline"  // online get patrika entry list (GET)
export const uploadFileData = BaseIP+ "muhurt/transaction/uploadPatrikaFile"  // online get patrika entry list (GET)