import React from 'react'
import logo from '../assets/img/logo.png';
import MainPage from './../components/MainPage';
import { useNavigate } from 'react-router-dom';

const TermsCondition = () => {
 const navigate = useNavigate();

  return (
    <>
     <div className='container-fluid p-1 p-lg-5 pt-lg-0' style={{height:"100%", backgroundColor: 'grey'}}>
          <div className='row m-0 '>

              <div className='col-12 text-center'>
                <img src={logo} className="img-fluid" alt="" style={{ pointerEvents: "none", userSelect: "none",width:"30%", }} />
              </div>

              <div className='col-12 px-md-5 text-end'>
                <div className='row m-0  px-lg-5 text-end'>
                  <div className='col-12 py-3 px-lg-4 text-end'>
                    <button className='py-1 px-2 rounded shadow'  onClick={() => navigate('/')} style={{border:"none",backgroundColor:"#bb4495",color:"white",}}>Back To Home</button>
                  </div>
                </div>
              </div>

        <div className='col-12 col-md-1'></div>
        <div className='col-12 col-md-10 p-2 p-md-5 border shadow' style={{backgroundColor:"white"}}>
          <h1 className='text-center'>Terms and Conditions</h1> <br/>
          <p>Welcome to <span>Yelavikar Astro!</span> By accessing or using our website, mobile application, or payment gateway, you agree to these Terms and Conditions. Please read them carefully.</p> <br/>

          <h5>1. Introduction</h5> <br/>
          <p>These Terms and Conditions govern your use of <span>Yelavikar Astro</span> services, including our website, mobile app, and any transactions made through our payment gateway. If you do not agree with any part of these terms, please refrain from using our services.</p> <br/>

          <h5>2. User Eligibility</h5> <br/>
          <p>•	You must be at least 18 years old to use our services.</p>
          <p>•	If you are accessing our services on behalf of another person or entity, you confirm that you have the authority to accept these terms.</p> <br/>

          <h5>3. Services Offered</h5> <br/>
          <p>•	Astrological consultations, reports, and personalized readings.</p>
          <p>•	Free and paid content, including horoscopes, predictions, and guidance.</p>
          <p>•	In-app purchases, subscription plans, and payment processing.</p> <br/>

          <h5>4. User Responsibilities</h5> <br/>
          <p>•	You agree to provide accurate information when using our services.</p>
          <p>•	You shall not use our platform for illegal, fraudulent, or unethical purposes.</p>
          <p>•	You are responsible for maintaining the confidentiality of your account credentials.</p> <br/>

          <h5>5. Payments & Refund Policy</h5> <br/>

          <h6>5.1 Payment Terms</h6> <br/>
          <p>•	Payments are processed through secure third-party gateways.</p>
          <p>•	All fees and charges are displayed before finalizing a purchase.</p> <br/>

          <h6>5.2 Refunds & Cancellations</h6> <br/>
          <p className='fw-bold'>•	Digital products (reports, consultations, etc.) are non-refundable.</p>
          <p>•	Subscription cancellations must be made before the next billing cycle.</p>
          <p>•	If a service is not delivered as promised, you may request a review by contacting support.</p> <br/>

          <h5>6. Intellectual Property</h5> <br/>
          <p>•	All content, including text, images, logos, and software, is the intellectual property of <span className='fw-bold'>Yelavikar Astro</span> and cannot be copied, modified, or distributed without permission.</p> <br/>

          <h5>7. Limitation of Liability</h5> <br/>
          <p>•	<span className='fw-bold'>Yelavikar Astro provides astrology-related services for informational and entertainment purposes only.</span> We do not guarantee specific results or outcomes.</p>
          <p>•	We are <span className='fw-bold'>not responsible </span> for any personal, financial, or legal decisions made based on our services.</p> <br/>

          <h5>8. Privacy & Data Protection</h5> <br/>
          <p>•	Your data is collected and handled as per our [Privacy Policy].</p>
          <p>•	We use encryption and security measures to protect user information.</p> <br/>

          <h5>9. Termination & Suspension</h5> <br/>
          <p>•	We reserve the right to suspend or terminate user access for violating these terms.</p>
          <p>•	Any fraudulent activity may result in legal action.</p> <br/>

          <h5>10. Changes to Terms</h5> <br/>
          <p>•	We may update these terms periodically. Continued use of our services after changes implies acceptance.</p> <br/>
         

        </div>
        <div className='col-12 col-md-1'></div>

    </div>
    </div>
  </>
  )
}

export default TermsCondition