import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import TitleLabel from '../CommonComponents/TitleLabel';
import { getCityMasterList1, getCountryMasterList1,getDistrictMasterList1,getModelMasterList1,getPartyMasterList1, getStateMasterList1, getWeekDaysMasterList1, savePatrikaEntry, updatePatrikaEntry } from './Services/PatrikaEntryApi';
import { toast, ToastContainer } from 'react-toastify';

const PatrikaEntryForm = () => {

//========================================================================================================
const location = useLocation();
const initialState = location.state ? location.state.data : {};
const isEditMode = location.state ? location.state.isEditMode : false;

const RegNumber=initialState.regNo;
//========================================================================================================
      

    const titleName = "नाव नोंदणी";
    const navigate = useNavigate();
    const data1=sessionStorage.getItem('token');
    const [token, setToken]=useState(null)

    useEffect(()=>{
      
      if(data1){
        setToken(data1)
      }
      else{
        setToken(null)
      }
  },[])


const [cities, setCities] = useState([]);
const [weekDay, setWeekDay] = useState([]);
const [countries, setCountries] = useState([]);
const [states, setStates] = useState([]);
const [districts, setDistricts] = useState([]);
const [partyNames, setPartyNames] = useState([]);
const [modelNames, setModelNames] = useState([]);



const [city, setCity] = useState(initialState.city || '');
const [country, setCountry] = useState(initialState.country || '');
const [partyName, setPartyName] = useState(initialState.party || '');
const [modelName, setModelName] = useState(initialState.model || '');
const [Name, setName] = useState(initialState.name || '');
const [Taluka, setTaluka] = useState(initialState.taluka || '');
const [State, setState] = useState(initialState.state || '');
const [District, setDistrict] = useState(initialState.district || '');
const [gender, setGender] = useState(initialState.gender || '');
const [language, setLanguage] = useState(initialState.language || '');

//==========================================================================
 const parts =initialState.birthDate ? initialState.birthDate.split('/'):[];
 const formattedBirthDate = `${parts[2]}-${parts[1]}-${parts[0]}`;

const [birthDate, setBirthDate] = useState(formattedBirthDate || '');

//=======================================================================
const [day, setDay] = useState(initialState.day || '');


const timeParts = initialState.birthTime ? initialState.birthTime.split(' ') : [];
const formattedTime = timeParts[0] || '';
const [birthTime, setBirthTime] = useState(formattedTime);

const timeParts1 = initialState.timeFrom ? initialState.timeFrom.split(' ') : [];
const formattedTime1 = timeParts1[0] || '';
const [timeFrom, setTimeFrom] = useState(formattedTime1);

const timeParts2 = initialState.timeTo ? initialState.timeTo.split(' ') : [];
const formattedTime2 = timeParts2[0] || '';
const [timeTo, setTimeTo] = useState(formattedTime2);




const [amount, setAmount] = useState(initialState.amount || '');
const [advanceAmount, setAdvanceAmount] = useState(initialState.advanceAmount || '');
const [balanceAmount, setBalanceAmount] = useState(initialState.balanceAmount || '');
const [birthPlace, setBirthPlace] = useState(initialState.birthPlace || '');
const [mobileNo, setMobileNo] = useState(initialState.mobileNo || '');
const [email, setEmail] = useState(initialState.email || '');
const [otherData, setOtherData] = useState(initialState.otherData || '');

const [isCheckboxChecked1, setIsCheckboxChecked1] = useState(initialState.birthTimeFlag || false);
const [isCheckboxChecked2, setIsCheckboxChecked2] = useState(initialState.timeFromToFlag || false);


//============================शहर यादी get api code==============================================================

const getCityData = () =>{

    axios
    .get(getCityMasterList1, {
        headers: {
            'token':data1
          }
    })
    .then((res) => {
        setCities(res.data);
        if(res.data == 'UNAUTHORIZED'){
            toast.warn('Your data is UNAUTHORIZED!!', {
              position: 'top-right',
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
            });
            sessionStorage.clear();
            navigate("/"); 
          }
    })
    .catch((err) => console.log(err))
  }
  useEffect(() =>{
    getCityData()
  },[])

  //============================weekDay यादी get api code====================================================

const getWeekDayData = () =>{

    axios
    .get(getWeekDaysMasterList1, {
        headers: {
            'token':data1
          }
    })
    .then((res) => {
        setWeekDay(res.data);
        if(res.data == 'UNAUTHORIZED'){
            toast.warn('Your data is UNAUTHORIZED!!', {
              position: 'top-right',
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
            });
            sessionStorage.clear();
            navigate("/"); 
          }
    })
    .catch((err) => console.log(err))
  }
  useEffect(() =>{
    getWeekDayData()
  },[])

  //============================देश यादी get api code==============================================================
  useEffect(() =>{
    getCountryData();
    getStateData(country);
    getDistrictData(State);
  },[country,State])


const getCountryData = () =>{

    axios
    .get(getCountryMasterList1, {
        headers: {
            'token':data1
          }
    })
    .then((res) => {
        setCountries(res.data);
        if(res.data == 'UNAUTHORIZED'){
            toast.warn('Your data is UNAUTHORIZED!!', {
              position: 'top-right',
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
            });
            sessionStorage.clear();
            navigate("/"); 
          }
    })
    .catch((err) => console.log(err))
  }

  const getStateData = (countryId) =>{

    axios
    .get(`${getStateMasterList1}?countryId=${countryId}`, {
        headers: {
            'token':data1
          }
    })
    .then((res) => {
        setStates(res.data);
        if(res.data == 'UNAUTHORIZED'){
            toast.warn('Your data is UNAUTHORIZED!!', {
              position: 'top-right',
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
            });
            sessionStorage.clear();
            navigate("/"); 
          }
    })
    .catch((err) => console.log(err))
  }

  const getDistrictData = (stateId) =>{

    axios
    .get(`${getDistrictMasterList1}?stateId=${stateId}`, {
        headers: {
            'token':data1
          }
    })
    .then((res) => {
        setDistricts(res.data);
        if(res.data == 'UNAUTHORIZED'){
            toast.warn('Your data is UNAUTHORIZED!!', {
              position: 'top-right',
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
            });
            sessionStorage.clear();
            navigate("/"); 
          }
    })
    .catch((err) => console.log(err))
  }

   //============================पार्टी नाव यादी get api code====================================================

const getPartyNameData = () =>{

    axios
    .get(getPartyMasterList1, {
        headers: {
            'token':data1
          }
    })
    .then((res) => {
        setPartyNames(res.data);
        if(res.data == 'UNAUTHORIZED'){
            toast.warn('Your data is UNAUTHORIZED!!', {
              position: 'top-right',
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
            });
            sessionStorage.clear();
            navigate("/"); 
          }
    })
    .catch((err) => console.log(err))
  }
  useEffect(() =>{
    getPartyNameData()
  },[])

   //============================मोडेल नाव यादी get api code====================================================

const getModelNameData = () =>{

    axios
    .get(getModelMasterList1, {
        headers: {
            'token':data1
          }
    })
    .then((res) => {
        setModelNames(res.data);
        if(res.data == 'UNAUTHORIZED'){
            toast.warn('You are UNAUTHORIZED!!', {
              position: 'top-right',
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
            });
            sessionStorage.clear();
            navigate("/"); 
          }
    })
    .catch((err) => console.log(err))
  }
  useEffect(() =>{
    getModelNameData()
  },[])

//===============================================================================

 const handleName = (e) =>{
    setName(e.target.value)
 }
 const handleGender = (e) =>{
    setGender(e.target.value)
  }
//  const handleCity = (e) =>{
//     setCity(e.target.value)
//   }
// Handle Country Selection
const handleCountry = (e) => {
    const selectedCountryId = e.target.value;
    setCountry(selectedCountryId);

    setState("")
    setDistrict("")
    
    // Fetch States for the selected Country
    if (selectedCountryId) {
      getStateData(selectedCountryId);
    }
  };
  
  // Handle State Selection
  const handleState = (e) => {
    const selectedStateId = e.target.value;
    setState(selectedStateId);
    setDistrict("")
    // Fetch Districts for the selected State
    if (selectedStateId) {
      getDistrictData(selectedStateId);
    }
  };
 const handleDistrict = (e) =>{
    setDistrict(e.target.value)
  }
  const handleTaluka = (e) =>{
    setTaluka(e.target.value)
  }
 const handlePartyName = (e) =>{
    setPartyName(e.target.value)
  }

//  const handleModelName = (e) =>{
//     setModelName(e.target.value)
//   }
const handleModelName = (e) => {
    const selectedModelId = e.target.value;  
    const selectedModel = modelNames.find(model => model.id == selectedModelId); 

    if (selectedModel) {
      setAmount(selectedModel.amount);
    } else {
      setAmount('');
    } 
    setModelName(selectedModelId);
  }
  


 const handleLanguage = (e) =>{
    setLanguage(e.target.value)
  }

//  const handleBirthDate = (e) =>{
//     setBirthDate(e.target.value)
//   }

const handleBirthDate = (event) => {
    const selectedDate = event.target.value;
    setBirthDate(selectedDate);

    const selectedDay = new Date(selectedDate).getDay(); 
    setDay(selectedDay === 0 ? 7 : selectedDay); 
};


 const handleDay = (e) =>{
    setDay(e.target.value)
  }

 const handleBirthTime = (e) =>{
    setBirthTime(e.target.value)
  }

 const handleTimeFrom = (e) =>{
    setTimeFrom(e.target.value)
  }

 const handleTimeTo = (e) =>{
    setTimeTo(e.target.value)
  }

 const handleAmount = (e) =>{
    setAmount(e.target.value)
  }

 const handleAdvanceAmount = (e) =>{
    setAdvanceAmount(e.target.value)
  }

 const handleBalanceAmount= (e) =>{
    setBalanceAmount(e.target.value)
  }

 const handleBirthPlace= (e) =>{
    setBirthPlace(e.target.value)
  }

 const handleMobileNo= (e) =>{
    setMobileNo(e.target.value)
  }
 const handleEmailId= (e) =>{
    setEmail(e.target.value)
  }

 const handleOtherData= (e) =>{
    setOtherData(e.target.value)
  }

  const handleCheckboxChange1 = (e) => {
    setIsCheckboxChecked1(e.target.checked);
    setIsCheckboxChecked2(false);
    if (e.target.checked) {
        setTimeFrom(null);
        setTimeTo(null);
    }
  };
  
  const handleCheckboxChange2 = (e) => {
    setIsCheckboxChecked2(e.target.checked);
    setIsCheckboxChecked1(false);
    if (e.target.checked) {
      setBirthTime(null);
    }
  };

  //===========================================================================
  
//============save data code===========

const SaveData = (e) =>{
    e.preventDefault();

    const CheckBox1 = document.getElementById("flexCheckDefault1").checked ? 1 : 0;
    const CheckBox2 = document.getElementById("flexCheckDefault2").checked ? 1 : 0;

    const saveObj = {
      name:Name,
      gender:gender,
      language:language,
      birthDate:birthDate,
      day:day,
      model:modelName,
      birthTime:birthTime,
      timeFrom:timeFrom,
      timeTo:timeTo,
      amount:amount,
      advanceAmount:advanceAmount,
      balanceAmount:balanceAmount,
      birthPlace:birthPlace,
      city:city,
      taluka:Taluka,
      district:parseInt(District),
      state:parseInt(State),
      country:parseInt(country),
      party:partyName,
      mobileNo:mobileNo,
      email:email,
      otherData:otherData,
      birthTimeFlag:CheckBox1,
      timeFromToFlag:CheckBox2,
      entryFrom:1
    }

    axios
    .post(savePatrikaEntry, saveObj,{
        headers: {
          'token':token 
        }
      })
    .then((response) => {
      if (response.data == "SAVED") {
        toast.success("Data Saved Successfully!!!",{
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,     
            pauseOnHover: true,
            draggable: true,
          });

        navigate("/pt001/")

      } else if (response.data == "ALREADYEXISTS") {
        toast.warn("Data Already Exist!!!", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      }else if(response.data == 'UNAUTHORIZED'){
        toast.warn('Your data is UNAUTHORIZED!!', {
          position: 'top-right',
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
        sessionStorage.clear();
        navigate("/"); 
      }
       else {
        toast.error("Please Select All Fields !!!", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      }
    })
    .catch((err) => {
      console.log(err);
      toast.error('Catch Block:' + err.message, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    });
  };

  //  //==========update data code=====================

  
  const handleUpdate = () =>{

    const CheckBox1 = document.getElementById("flexCheckDefault1").checked ? 1 : 0;
    const CheckBox2 = document.getElementById("flexCheckDefault2").checked ? 1 : 0;
  
        const updateObj ={
            regNo:RegNumber,
            name:Name,
            gender:gender,
            language:language,
            birthDate:birthDate,
            day:day,
            model:modelName,
            birthTime:birthTime,
            timeFrom:timeFrom,
            timeTo:timeTo,
            amount:amount,
            advanceAmount:advanceAmount,
            balanceAmount:balanceAmount,
            birthPlace:birthPlace,
            city:city,
            taluka:Taluka,
            district:parseInt(District),
            state:parseInt(State),
            country:parseInt(country),
            party:partyName,
            mobileNo:mobileNo,
            email:email,
            otherData:otherData,
            birthTimeFlag:CheckBox1,
            timeFromToFlag:CheckBox2,
            // type:3
        }
        
    axios
    .put(updatePatrikaEntry,updateObj,{ 
            headers: {
              'token':data1
            }
    })
    .then((res) =>{

      if(res.data ==="UPDATED"){
        navigate("/pt001/")
        

        toast.success('Data Updated Successfully!!', {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
  
      }
      else if(res.data ==="ALREADYEXISTS"){
        toast.warn('Data Already Exist!!', {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
      }
      else if(res.data == 'UNAUTHORIZED'){
        toast.warn('Your data is UNAUTHORIZED!!', {
          position: 'top-right',
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
        sessionStorage.clear();
        navigate("/"); 
      }
    })
    .catch((err) =>{
      console.log(err);
    })
  }


  //=====================================================================================
    return (
        <>
            <div className="container">
                <TitleLabel titleName={titleName} />
                <div style={{ backgroundColor: "#fff", padding: "16px", boxShadow: "0px 2px 15px #CECECE", marginTop: "16px", marginBottom: "20px" }}>
                    <form>
                       

                        <div className="form-group row my-2">
                            <div className="col-sm-7">
                                <div className="row">
                                    <label htmlFor="inputName" className="col-sm-2 col-form-label" style={{ fontWeight: "600", fontSize: "14px" }}>नाव : </label>
                                    <div className="col-sm-8">
                                        <input type="text" className="form-control form-control-sm" id="inputName" placeholder="नाव" value={Name} onChange={handleName} />
                                    </div>
                                </div>

                            </div>
                            <div className="col-sm-5">
                                <div className="row">
                                    <label htmlFor="inputName" className="col-sm-4 col-form-label" style={{ fontWeight: "600", fontSize: "14px" }}>लिंग : </label>
                                    <div className="col-sm-7">
                                        <select className="form-select form-select-sm" aria-label=".form-select-sm example" value={gender} onChange={handleGender}>
                                            <option value="" selected disabled>लिंग निवडा</option>
                                            <option value="1">पुरुष</option>
                                            <option value="2">स्त्री</option>
                                        </select>
                                    </div>
                                </div>

                            </div>
                           
                        </div>

                        <div className="form-group row my-2">
                            <div className="col-sm-7">
                                <div className="row">
                                    <label htmlFor="inputName" className="col-sm-2 col-form-label" style={{ fontWeight: "600", fontSize: "14px" }}>जन्मतारीख : </label>
                                    <div className="col-sm-4">
                                        <input type="date" className="form-control form-control-sm" id="inputName" placeholder="जन्मतारीख"  value={birthDate} onChange={handleBirthDate}/>
                                    </div>

                                    <div className="col-sm-4">
                                        <select className="form-select form-select-sm" aria-label=".form-select-sm example"
                                            value={day} 
                                            onChange={handleDay}
                                            required disabled
                                            style={{borderRadius:"5px",height:"38px",backgroundImage:"none",}}
                                            >
                                            <option >वार निवडा</option>
                                            {weekDay.map((weekDayItem) => (
                                            <option key={weekDayItem.id} value={weekDayItem.id}>
                                                {weekDayItem.weekDay}
                                            </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>

                            </div>
                            <div className="col-sm-5">
                                <div className="row">
                                    <label htmlFor="inputName" className="col-sm-4 col-form-label" style={{ fontWeight: "600", fontSize: "14px" }}>भाषा : </label>
                                    <div className="col-sm-7">
                                        <select className="form-select form-select-sm" aria-label=".form-select-sm example" value={language} onChange={handleLanguage}>
                                            <option value="" selected disabled>भाषा निवडा</option>
                                            <option value="1">मराठी</option>
                                            <option value="2">इंग्रजी</option>
                                        </select>
                                    </div>
                                </div>

                            </div>
                           
                        </div>

                        <div className="form-group row my-2">
                            <div className="col-sm-7">
                                <div className="row">
                                    <label htmlFor="inputName" className="col-sm-2 col-form-label" style={{ fontWeight: "600", fontSize: "14px" }}>जन्मवेळ : </label>

                                    <div className="col-sm-1">
                                        <input
                                            className="form-check-input"
                                            type="checkbox"
                                            id="flexCheckDefault1"
                                            checked={isCheckboxChecked1}
                                            onChange={handleCheckboxChange1}
                                        />
                                    </div>

                                    <div className="col-sm-3">
                                        <input type="time" className="form-control form-control-sm" id="inputName" placeholder="जन्मवेळ" value={birthTime || ''} onChange={handleBirthTime} disabled={!isCheckboxChecked1} />
                                    </div>

                                </div>

                            </div>
                            <div className="col-sm-5">
                                <div className="row">
                                    <label htmlFor="inputName" className="col-sm-4 col-form-label" style={{ fontWeight: "600", fontSize: "14px" }}>मोडेल : </label>
                                    <div className="col-sm-7">
                                    <select className="form-select form-select-sm" aria-label=".form-select-sm example"
                                            value={modelName}
                                            onChange={handleModelName}
                                            required
                                            style={{borderRadius:"5px",height:"38px"}}
                                        >
                                            <option >मोडेल नाव निवडा</option>
                                            {modelNames.map((modelNameItem) => (
                                            <option key={modelNameItem.id} value={modelNameItem.id}>
                                                {modelNameItem.modelName}
                                            </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>

                            </div>
                           
                        </div>


                        <div className="form-group row my-2">
                            <div className="col-sm-7">
                                <div className="row">
                                    <label htmlFor="inputName" className="col-sm-2 col-form-label" style={{ fontWeight: "600", fontSize: "14px" }}>वेळ पासून : </label>

                                    <div className="col-sm-1">
                                        {/* <input className="form-check-input" type="checkbox" value="0" id="flexCheckDefault" /> */}
                                        <input
                                            className="form-check-input"
                                            type="checkbox"
                                            id="flexCheckDefault2"
                                            checked={isCheckboxChecked2}
                                            onChange={handleCheckboxChange2}
                                        />
                                    </div>

                                    <div className="col-sm-3">
                                        <input type="time" className="form-control form-control-sm" id="inputName" placeholder="जन्मवेळ" value={timeFrom || ''} onChange={handleTimeFrom} disabled={!isCheckboxChecked2}/>
                                    </div>

                                    <label htmlFor="inputName" className="col-sm-1 col-form-label" style={{ fontWeight: "600", fontSize: "14px" }}>पर्यंत: </label>
                                    <div className="col-sm-3">
                                        <input type="time" className="form-control form-control-sm" id="inputName" placeholder="जन्मवेळ" value={timeTo || ''} onChange={handleTimeTo} disabled={!isCheckboxChecked2}/>
                                    </div>

                                </div>

                            </div>
                            <div className="col-sm-5">
                                <div className="row">
                                    <label htmlFor="inputName" className="col-sm-4 col-form-label" style={{ fontWeight: "600", fontSize: "14px" }}>रक्कम : </label>
                                    <div className="col-sm-7">
                                        <input type="number" className="form-control form-control-sm" id="inputName" value={amount} disabled onChange={handleAmount}/>
                                    </div>
                                </div>

                            </div>
                           
                        </div>

                        <div className="form-group row my-2">
                            <div className="col-sm-7">
                                <div className="row">
                                    <label htmlFor="inputName" className="col-sm-2 col-form-label" style={{ fontWeight: "600", fontSize: "14px" }}>जन्मस्थळ : </label>
                                    <div className="col-sm-8">
                                        <input type="text" className="form-control form-control-sm" id="inputName" placeholder="जन्मस्थळ" value={birthPlace} onChange={handleBirthPlace} />
                                    </div>
                                </div>

                            </div>
                            <div className="col-sm-5">
                                <div className="row">
                                    <label htmlFor="inputName" className="col-sm-4 col-form-label" style={{ fontWeight: "600", fontSize: "14px" }}>अडवान्स : </label>
                                    <div className="col-sm-7">
                                        <input type="number" className="form-control form-control-sm" id="inputName" value={advanceAmount} onChange={handleAdvanceAmount} />
                                    </div>
                                </div>

                            </div>
                            
                        </div>

                        <div className="form-group row my-2">
                            <div className="col-sm-7">
                            <div className="row">
                                    <label htmlFor="inputName" className="col-sm-2 col-form-label" style={{ fontWeight: "600", fontSize: "14px" }}>देश(जन्मस्थळ) : </label>
                                        <div className="col-sm-8">
                                    <select className="form-select form-select-sm" aria-label=".form-select-sm example"
                                            value={country}
                                            onChange={handleCountry}
                                            required
                                            style={{borderRadius:"5px",height:"38px"}}
                                        >
                                            <option >देश निवडा</option>
                                            {countries.map((countryItem) => (
                                            <option key={countryItem.id} value={countryItem.id}>
                                                {countryItem.countryName}
                                            </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>

                            </div>
                            <div className="col-sm-5">
                                <div className="row">
                                    <label htmlFor="inputName" className="col-sm-4 col-form-label" style={{ fontWeight: "600", fontSize: "14px" }}>शिल्लक : </label>
                                    <div className="col-sm-7">
                                        <input type="number" className="form-control form-control-sm" id="inputName" value={balanceAmount}  onChange={handleBalanceAmount}/>
                                    </div>
                                </div>

                            </div>
                           
                        </div>

                        <div className="form-group row my-2">
                            <div className="col-sm-7">
                                <div className="row">
                                    <label htmlFor="inputName" className="col-sm-2 col-form-label" style={{ fontWeight: "600", fontSize: "14px" }}>राज्य(जन्मस्थळ): </label>
                                        <div className="col-sm-8">
                                    <select className="form-select form-select-sm" aria-label=".form-select-sm example"
                                            value={State}
                                            onChange={handleState}
                                            required
                                            style={{borderRadius:"5px",height:"38px"}}
                                        >
                                            <option >राज्य निवडा</option>
                                            {states.map((stateItem) => (
                                            <option key={stateItem.id} value={stateItem.id}>
                                                {stateItem.stateName}
                                            </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>

                            </div>
                            <div className="col-sm-5">
                                <div className="row">
                                    <label htmlFor="inputName" className="col-sm-4 col-form-label" style={{ fontWeight: "600", fontSize: "14px" }}>ज्योतिष पार्टी नाव : </label>
                                    <div className="col-sm-7">
                                    <select className="form-select form-select-sm" aria-label=".form-select-sm example"
                                            value={partyName}
                                            onChange={handlePartyName}
                                            required
                                            style={{borderRadius:"5px",height:"38px",color:"black"}}
                                        >
                                            <option>पार्टी नाव निवडा</option>
                                            {partyNames.map((partyNameItem) => (
                                            <option key={partyNameItem.id} value={partyNameItem.id}>
                                                {partyNameItem.partyName}
                                            </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>

                            </div>
                           
                        </div>

                        <div className="form-group row my-2">

                            <div className="col-sm-7">
                                <div className="row">
                                    <label htmlFor="inputName" className="col-sm-2 col-form-label" style={{ fontWeight: "600", fontSize: "14px" }}>जिल्हा(जन्मस्थळ): </label>
                                        <div className="col-sm-8">
                                    <select className="form-select form-select-sm" aria-label=".form-select-sm example"
                                            value={District}
                                            onChange={handleDistrict}
                                            required
                                            style={{borderRadius:"5px",height:"38px"}}
                                        >
                                            <option >जिल्हा निवडा</option>
                                            {districts.map((districtItem) => (
                                            <option key={districtItem.id} value={districtItem.id}>
                                                {districtItem.districtName}
                                            </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>

                            </div>


                            <div className="col-sm-5 mb-2">
                                <div className="row">
                                    <label htmlFor="inputName" className="col-sm-4 col-form-label" style={{ fontWeight: "600", fontSize: "14px" }}>इत्तर माहिती : </label>
                                    <div className="col-sm-7">
                                        <textarea class="form-control" value={otherData} onChange={handleOtherData} rows="3" id="comment" placeholder='इत्तर माहिती'></textarea>
                                    </div>
                                </div>

                            </div>

                            <div className="col-sm-7">
                                <div className="row">
                                    <label htmlFor="inputName" className="col-sm-2 col-form-label" style={{ fontWeight: "600", fontSize: "14px" }}>तालुका(जन्मस्थळ): </label>
                                        <div className="col-sm-8">
                                        <input type="text" className="form-control form-control-sm" id="inputName" placeholder="तालुका" value={Taluka} onChange={handleTaluka} />
                                    </div>
                                </div>

                            </div>


                            <div className="col-sm-5">
                                <div className="row">
                                    <label htmlFor="inputName" className="col-sm-4 col-form-label" style={{ fontWeight: "600", fontSize: "14px" }}>मोबाईल नं : </label>
                                    <div className="col-sm-7">
                                        <input type="tel" pattern="[0-9]{10}"  value={mobileNo} onChange={handleMobileNo}
                                       maxlength="10" className="form-control form-control-sm" id="inputNumber" />
                                    </div>
                                </div>

                            </div>

                        </div>

                        <div className="form-group row my-2">
                            <div className="col-sm-7">
                                <div className="row">
                                    <label htmlFor="inputName" className="col-sm-2 col-form-label" style={{ fontWeight: "600", fontSize: "14px" }}>ई-मेल : </label>
                                    <div className="col-sm-8">
                                        <input type='email' class="form-control" value={email} onChange={handleEmailId} rows="3" id="comment" placeholder='ई-मेल'></input>
                                    </div>
                                </div>

                            </div>
                            <div className="col-sm-5">  </div>

                        </div>

                        {/* <div className="form-group row my-3">
                            <div className="col-sm-7">
                                <div className="col-sm-6 offset-sm-2">
                                    <button type="submit" className="btn btn-sm saveBtn text-light" onClick={SaveData}>Save</button>
                                    <button type="button" onClick={()=>navigate("/pt001/")} className="btn btn-sm mx-2 cancleBtn text-light">Cancel</button>
                                </div>

                                {showUpdateButton && (
                                         <>
                                         <button className="btn btn-sm mx-2 text-light" type='submit' style={{color:"black",backgroundColor:"orangered",border:"1px solid orangered"}} onClick={handleUpdate}>Update</button>

                                        <button type="submit" className="btn btn-sm mx-2 cancleBtn text-light" onClick={cancleEntry1}>Cancel</button>
                                         </>

                                         )}
                            </div>
                        </div> */}

                        <div className="col-sm-6 offset-sm-2">
                            {isEditMode ? (
                                <>
                                    <button type="submit" className="btn btn-sm saveBtn text-light" onClick={handleUpdate}>
                                        Update
                                    </button>
                                    <button type="button" className="btn btn-sm mx-2 cancleBtn text-light"  onClick={() => navigate("/pt001/")}>
                                        Cancel
                                    </button>
                                </>
                            ) : (
                                <>
                                    <button type="submit" className="btn btn-sm saveBtn text-light" onClick={SaveData}>
                                        Save
                                    </button>
                                    <button type="button" onClick={() => navigate("/pt001/")} className="btn btn-sm mx-2 cancleBtn text-light">
                                        Cancel
                                    </button>
                                </>
                            )}
                        </div>

                    </form>
                </div>
            </div>
            <ToastContainer theme='colored' />
        </>
    )
}

export default PatrikaEntryForm