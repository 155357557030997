import React, { useEffect, useState } from 'react'
import TitleLabel from '../../CommonComponents/TitleLabel'
import muhurtImage from '../../assets/img/muhurth.png';
import patrikaImage from '../../assets/img/patrika.png';
import gunmilanImage from '../../assets/img/gunmilan.png';
import pendingImg from '../../assets/img/pending.png';
import createdImg from '../../assets/img/created.png';
import deliveredImg from '../../assets/img/delivered.png';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { getDailyReportDataAPIURL } from '../Services/DailyReportApi';


const DailyReportForm = () => {
    const titleName = 'Daily Report'
    const navigate = useNavigate();
    const data1 = sessionStorage.getItem('token');
    const [data, setData] = useState({});

    const getData = () => {
        // console.log(getDailyReportDataAPIURL)
        axios
            .get(getDailyReportDataAPIURL,
                {
                    headers: {
                        'token': data1
                    }
                }
            )
            .then((res) => {
                setData(res.data);
                if (res.data == 'UNAUTHORIZED') {
                    toast.warn('Your data is UNAUTHORIZED!!', {
                        position: 'top-right',
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    });
                    sessionStorage.clear();
                    navigate("/");
                }
            })
            .catch((err) => console.log(err))
    }

    useEffect(() => {
        getData()
    }, [])

    return (
        <>
            <div className="container">
                <TitleLabel titleName={titleName} />
                <div style={{ backgroundColor: "#fff", padding: "16px", boxShadow: "0px 2px 15px #CECECE", marginTop: "16px", marginBottom: "20px" }}>
                    <form>
                        <div>
                            <div style={{ display: "flex", alignItems: "center", marginBottom: "16px" }}>
                                <h5 style={{ fontSize: "20px", fontWeight: "600", color: "rgb(195, 20, 50)", marginRight: "16px" }}>Online</h5>
                                <hr style={{ flexGrow: "1", border: "0", borderTop: "2px solid rgb(36, 11, 54)" }} />
                            </div>

                            <div className="row" style={{ marginTop: "20px" }}>
                                {/* Tile 1: Muhurt */}
                                <div className="col-sm-3" style={{ marginBottom: "20px" }}>
                                    <div style={{
                                        backgroundColor: "#f7f7f7", padding: "20px", borderRadius: "8px", boxShadow: "0px 2px 10px #CECECE", textAlign: "center",
                                        backgroundColor: "#DB6885",
                                        backgroundImage: "linear-gradient(315deg, #DB6885 0%, #972239 74%)"
                                    }}>
                                        <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                            <img src={muhurtImage} alt="Muhurt" style={{ width: "80px", height: "80px", marginRight: "16px" }} />
                                            <div style={{ textAlign: "right" }}>
                                                <p style={{ fontSize: "20px", fontWeight: "600", color: "rgb(36, 11, 54)", marginBottom: "4px" }}>Muhurt</p>
                                                <p style={{ fontSize: "18px", fontWeight: "600", color: "rgb(195, 20, 50)", marginBottom: "4px" }}>{data.muhurtCount}</p>
                                                <p style={{ fontSize: "18px", fontWeight: "600", color: "rgb(195, 20, 50)" }}>₹{data.muhurtAmount}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                                {/* Tile 2: Patrika */}
                                <div className="col-sm-3" style={{ marginBottom: "20px" }}>
                                    <div style={{
                                        backgroundColor: "#f7f7f7", padding: "20px", borderRadius: "8px", boxShadow: "0px 2px 10px #CECECE", textAlign: "center",
                                        backgroundColor: "#0047ab",
                                        backgroundImage: "linear-gradient(315deg, #0047ab 0%, #1ca9c9 74%)"
                                    }}>
                                        <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                            <img src={patrikaImage} alt="Patrika" style={{ width: "80px", height: "80px", marginRight: "16px" }} />
                                            <div style={{ textAlign: "right" }}>
                                                <p style={{ fontSize: "20px", fontWeight: "600", color: "rgb(36, 11, 54)", marginBottom: "4px" }}>Patrika</p>
                                                <p style={{ fontSize: "18px", fontWeight: "600", color: "rgb(195, 20, 50)", marginBottom: "4px" }}>{data.patrikaCount}</p>
                                                <p style={{ fontSize: "18px", fontWeight: "600", color: "rgb(195, 20, 50)" }}>₹{data.patrikaAmount}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* Tile 3: Gunmilan */}
                                <div className="col-sm-3" style={{ marginBottom: "20px" }}>
                                    <div style={{
                                        backgroundColor: "#f7f7f7", padding: "20px", borderRadius: "8px", boxShadow: "0px 2px 10px #CECECE", textAlign: "center",
                                        backgroundColor: "#FFC857",
                                        backgroundImage: "linear-gradient(315deg, #FFC857 0%, #C11D38 74%)"
                                    }}>
                                        <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                            <img src={gunmilanImage} alt="Gunmilan" style={{ width: "80px", height: "80px", marginRight: "16px" }} />
                                            <div style={{ textAlign: "right" }}>
                                                <p style={{ fontSize: "20px", fontWeight: "600", color: "rgb(36, 11, 54)", marginBottom: "4px" }}>Gunmilan</p>
                                                <p style={{ fontSize: "18px", fontWeight: "600", color: "rgb(195, 20, 50)", marginBottom: "4px" }}>{data.gunMilanCount}</p>
                                                <p style={{ fontSize: "18px", fontWeight: "600", color: "rgb(195, 20, 50)" }}>₹{data.gunMilanAmount}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div>
                            <div style={{ display: "flex", alignItems: "center", marginBottom: "16px" }}>
                                <h5 style={{ fontSize: "20px", fontWeight: "600", color: "rgb(195, 20, 50)", marginRight: "16px" }}>Offline</h5>
                                <hr style={{ flexGrow: "1", border: "0", borderTop: "2px solid rgb(36, 11, 54)" }} />
                            </div>

                            <div className="row" style={{ marginTop: "20px" }}>
                                {/* Tile 1: Muhurt */}
                                <div className="col-sm-3" style={{ marginBottom: "20px" }}>
                                    <div style={{
                                        backgroundColor: "#f7f7f7", padding: "20px", borderRadius: "8px", boxShadow: "0px 2px 10px #CECECE", textAlign: "center",
                                        backgroundColor: "#DB6885",
                                        backgroundImage: "linear-gradient(315deg, #DB6885 0%, #972239 74%)"
                                    }}>
                                        <div style={{
                                            display: "flex", alignItems: "center", justifyContent: "space-between"
                                        }}>
                                            <img src={pendingImg} alt="Muhurt" style={{ width: "80px", height: "80px", marginRight: "16px" }} />
                                            <div style={{ textAlign: "right" }}>
                                                <p style={{ fontSize: "19px", fontWeight: "600", color: "rgb(36, 11, 54)" }}>Pending Patrika</p>
                                                <p style={{ fontSize: "22px", fontWeight: "700", color: "rgb(195, 20, 50)" }}>{data.patrikaCountPending}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                                {/* Tile 2: Patrika */}
                                <div className="col-sm-3" style={{ marginBottom: "20px" }}>
                                    <div style={{
                                        backgroundColor: "#f7f7f7", padding: "20px", borderRadius: "8px", boxShadow: "0px 2px 10px #CECECE", textAlign: "center"
                                        , backgroundColor: "#0047ab",
                                        backgroundImage: "linear-gradient(315deg, #0047ab 0%, #1ca9c9 74%)"
                                    }}>
                                        <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                            <img src={createdImg} alt="Patrika" style={{ width: "80px", height: "80px", marginRight: "16px" }} />
                                            <div style={{ textAlign: "right" }}>
                                                <p style={{ fontSize: "19px", fontWeight: "600", color: "rgb(36, 11, 54)" }}>Created Patrika</p>
                                                <p style={{ fontSize: "22px", fontWeight: "700", color: "rgb(195, 20, 50)" }}>{data.patrikaCountCreated}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* Tile 3: Gunmilan */}
                                <div className="col-sm-3" style={{ marginBottom: "20px" }}>
                                    <div style={{
                                        backgroundColor: "#f7f7f7", padding: "20px", borderRadius: "8px", boxShadow: "0px 2px 10px #CECECE", textAlign: "center"
                                        , backgroundColor: "#FFC857",
                                        backgroundImage: "linear-gradient(315deg, #FFC857 0%, #C11D38 74%)"
                                    }}>
                                        <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                            <img src={deliveredImg} alt="Gunmilan" style={{ width: "80px", height: "80px", marginRight: "16px" }} />
                                            <div style={{ textAlign: "right" }}>
                                                <p style={{ fontSize: "19px", fontWeight: "600", color: "rgb(36, 11, 54)" }}>Delivered Patrika</p>
                                                <p style={{ fontSize: "22px", fontWeight: "700", color: "rgb(195, 20, 50)" }}>{data.patrikaCountDelivered}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div >
            </div >
        </>
    )
}

export default DailyReportForm;
