import React from 'react';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { useNavigate } from "react-router";
import { useDispatch } from 'react-redux';
import { logout } from '../reduxFeature/user';
import 'bootstrap/dist/css/bootstrap.css';
import logo from '../assets/img/logo-color.png';
import MenuIcon from '@mui/icons-material/Menu';
import { Nav, Navbar, NavDropdown, Container } from 'react-bootstrap';
import "../App.css"


const MasterHeader = ({ setIsUser }) => {

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const navbg = {
    background: "#c31432",
    background: "-webkit-linear-gradient(to right, #240b36, #c31432)",
    background: "linear-gradient(to right, #240b36, #c31432)",
    boxShadow: "0 2px 4px -2px rgba(0,0,0,.5)",
  }
  const navtxt = {
    color: "#ffff",
    fontSize: "14px",
    fontWeight: "500",
    letterSpacing: "1px",
    textDecoration: "none",
  }
  const navtxt1 = {
    color: "#ffff",
    fontSize: "14px",
    fontWeight: "500",
    letterSpacing: "1px",
    textDecoration: "none",
  }

  const logoutApp = () => {
    sessionStorage.clear();
    dispatch(logout());
    setIsUser(false);
    navigate("/");    
  }

  return (
    <>
      <Navbar collapseOnSelect expand="lg" bg="light" variant="dark" style={navbg}>
        <Container>
          <Navbar.Brand><img src={logo} alt="" width="200px" /></Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" style={{ color: "#fff", border: "1px solid #fff" }} >
            <MenuIcon fontSize='medium' />
          </Navbar.Toggle>
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="me-auto"></Nav>
            <Nav>
              <Nav.Link style={navtxt} href="/dashboard/">Dashboard</Nav.Link>

              <NavDropdown style={navtxt1} title={<span style={navtxt}>Master</span>} id="basic-nav-dropdown">
              {/* <NavDropdown title="Master" id="basic-nav-dropdown"> */}
                
                <NavDropdown.Item className='navText1' href="/m001/">Muharta Master</NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item className='navText1' href="/m002/">Patrika Party Master</NavDropdown.Item>
                <NavDropdown.Item className='navText1' href="/m003/">Model Master</NavDropdown.Item>
                <NavDropdown.Item className='navText1' href="/m004/">City Master</NavDropdown.Item>
                <NavDropdown.Item className='navText1' href="/m005/">Country Master</NavDropdown.Item>
                <NavDropdown.Item className='navText1' href="/m006/">Week Day Master</NavDropdown.Item>
                <NavDropdown.Item className='navText1' href="/m007/">Time Master</NavDropdown.Item>
                <NavDropdown.Item className='navText1' href="/m008/">Rashi Master</NavDropdown.Item>
                <NavDropdown.Item className='navText1' href="/m009/">Year Master</NavDropdown.Item>
                <NavDropdown.Item className='navText1' href="/m010/">Type Master</NavDropdown.Item>
                <NavDropdown.Item className='navText1' href="/m011/">Marathi Month Master</NavDropdown.Item>

              </NavDropdown>

              <Nav.Link style={navtxt} href='/mde001/' >Muhurta Data Entry</Nav.Link>
              {/* <Nav.Link style={navtxt} href="/pt001/">Patrika Entry</Nav.Link> */}
              <NavDropdown style={navtxt1} title={<span style={navtxt}>Patrika Entry</span>} id="basic-nav-dropdown">
                
                <NavDropdown.Item className='navText1' href="/pt001/">Offline Patrika</NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item className='navText1' href="/opt001/">Online Patrika</NavDropdown.Item>

              </NavDropdown>

              <Nav.Link style={navtxt} href="/gm001/">Gun Milan</Nav.Link>
              <Nav.Link style={navtxt} href="/dr001/">Daily Report</Nav.Link>
              {/* <Nav.Link style={navtxt} href="#/b001/">Report</Nav.Link> */}

              <Nav.Link style={navtxt} className="fw-bolder" onClick={() => logoutApp()}>Logout <ExitToAppIcon fontSize='small' /></Nav.Link>

            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>

    </>
  )
}

export default MasterHeader;