// export const BaseIP = 'http://192.168.29.102:8082/techno-muhurt/';  //local
// export const BaseIP = 'http://103.104.74.225:9080/techno-muhurt/';    //online
export const BaseIP = 'http://157.66.191.17:9080/techno-muhurt/';  //(online 2nd)



// export const getReportDataApi= "http://192.168.29.102:8082/" // get Report Data Api  (local)
// export const getReportDataApi= "http://103.104.74.225:9080/" // get Report Data Api  (online)
export const getReportDataApi= "http://157.66.191.17:9080/" // get Report Data Api  (online 2nd)

export const adminLogin= BaseIP+ "muhurt/user/signIn"

// export const BaseURL = "http://192.168.29.102:8082";  //local
// export const BaseURL = "http://103.104.74.225:9080"; // online
export const BaseURL = "http://157.66.191.17:9080"; //(online 2nd)

export const imagePath = BaseURL + "/muhurt/images/";
export const onlinePatrikaPath = BaseURL + "/patrika/";
export const gunMilanPath = BaseURL + "/gunmilan/";
