import React from 'react'
import logo from '../assets/img/logo.png';
import { useNavigate } from 'react-router-dom';

const ShippingDelivery = () => {

 const navigate = useNavigate();

  return (
    <>
      <div className='container-fluid p-1 p-lg-5 pt-lg-0' style={{height:"100%", backgroundColor: 'grey'}}>
          <div className='row m-0 '>

              <div className='col-12 text-center'>
                <img src={logo} className="img-fluid" alt="" style={{ pointerEvents: "none", userSelect: "none",width:"30%", }} />
              </div>

              <div className='col-12 px-md-5 text-end'>
                <div className='row m-0  px-lg-5 text-end'>
                  <div className='col-12 py-3 px-lg-4 text-end'>
                    <button className='py-1 px-2 rounded shadow'  onClick={() => navigate('/')} style={{border:"none",backgroundColor:"#bb4495",color:"white",}}>Back To Home</button>
                  </div>
                </div>
              </div>

              <div className='col-12 col-md-1'></div>
              <div className='col-12 col-md-10  p-2 p-md-5 border shadow' style={{backgroundColor:"white"}}>
                <h1 className='text-center'>Shipping and Delivery Policy</h1> <br/>
                <p>
                Welcome to <span className='fw-bold'>Yelavikar Astro!</span> This <span className='fw-bold'>Shipping and Delivery Policy</span> explains how we deliver our digital and physical products to our customers.
                </p>

                <h6>1. Digital Products & Services</h6><br/> 

                <h6>1.1 Delivery Time</h6> <br/>
                <p>•	Astrology reports, horoscopes, and digital consultations are delivered electronically via email or within your user dashboard.</p>
                <p>•	Delivery time depends on the service type:</p>
                <p>o	Instant reports: Delivered within 5-10 minutes after successful payment.</p>
                <p>o	Personalized reports & readings: Delivered within 24-48 hours (depending on complexity).</p>
                <p>o	Live consultations: Conducted as per the scheduled time chosen by the user.</p>                 <br/>

                <h6>1.2 Delivery Methods</h6> <br/>
                <p>•	All digital products are accessible through your Astrologic account and emailed to your registered email address.</p>
                <p>•	If you do not receive your digital product within the mentioned timeframe, please check your spam/junk folder or contact our support team.</p><br/>

                <h6>1.3 Failed Delivery</h6> <br/>

                <p>•	If an incorrect email address or contact details were provided during purchase, Astrologic is not responsible for failed delivery.</p>
                <p>•	In case of technical issues on our end, please contact our support team for resolution.</p> <br/>

                <h6>2. Physical Products (If Applicable)</h6>   <br/>

                <p>If Astrologic sells physical products (e.g., astrology books, gemstones, or accessories), the following policies apply:</p> <br/>

                <h6>2.1 Shipping Time</h6>  <br/>
                <p>•	Orders are processed within 2-3 business days after payment confirmation.</p>
                <p>•	Delivery times depend on the shipping destination:</p>
                <p>o	Domestic Shipping: 5-7 business days</p>
                <p>o	International Shipping: 10-15 business days (subject to customs clearance)</p> <br/>

                <h6>2.2 Shipping Charges</h6> <br/>
                <p>•	Shipping fees are calculated at checkout based on the delivery location and product weight.</p>
                <p>•	Free shipping may be available for specific products or promotions.</p>           <br/>

                <h6>2.3 Order Tracking</h6>

                <p>•	Once your order is shipped, you will receive a tracking number via email.</p>
                <p>•	Tracking updates can be checked through our website or courier partner’s website.</p> <br/>

                <h6>2.4 Delayed or Failed Delivery</h6> <br/>
                <p>O•	Delays due to customs, weather, or courier issues are beyond our control.</p>
                <p>•	If the product is undelivered due to incorrect shipping details provided by the user, Astrologic is not responsible for re-shipping costs.</p>
                 <br/>
               
              </div>
              <div className='col-12 col-md-1'></div>

          </div>
      </div>
    </>
  )
}

export default ShippingDelivery