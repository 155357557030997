import React from 'react'
import logo from '../assets/img/logo.png';
import { useNavigate } from 'react-router-dom';

const CancellationRefund = () => {

 const navigate = useNavigate();

  return (
    <>
      <div className='container-fluid p-1 p-lg-5 pt-lg-0' style={{height:"100%", backgroundColor: 'grey'}}>
          <div className='row m-0 '>

              <div className='col-12 text-center'>
                <img src={logo} className="img-fluid" alt="" style={{ pointerEvents: "none", userSelect: "none",width:"30%", }} />
              </div>

              <div className='col-12 px-md-5 text-end'>
                <div className='row m-0  px-lg-5 text-end'>
                  <div className='col-12 py-3 px-lg-4 text-end'>
                    <button className='py-1 px-2 rounded shadow'  onClick={() => navigate('/')} style={{border:"none",backgroundColor:"#bb4495",color:"white",}}>Back To Home</button>
                  </div>
                </div>
              </div>

              <div className='col-12 col-md-1'></div>
              <div className='col-12 col-md-10  p-2 p-md-5 border shadow' style={{backgroundColor:"white"}}>
                <h1 className='text-center'>Cancellation and Refund</h1> <br/>
                <p>
                At <span className='fw-bold'>Yelavikar Astro!</span> we strive to provide the best astrology services to our customers. Please read our <span className='fw-bold'>Cancellation and Refund Policy</span> carefully before making a purchase.
                </p>

                <h6>1. Cancellation Policy</h6><br/>

                <h6>1.1 Digital Products & Services</h6> <br/>
                <p>•	Once an order for a personalized astrology report, consultation, or reading is placed, it cannot be canceled as the service is customized and initiated immediately.</p>
                <p>•	Subscription plans can be canceled at any time, but access will remain until the end of the billing cycle.</p> <br/>

                <h6>1.2 Subscription Services</h6> <br/>
                <p>•	Users can cancel their subscription by going to the Account Settings section in the app or website.</p>
                <p>•	No partial refunds will be issued for subscription cancellations.</p><br/>

                <h6>1.3 Live Consultation Bookings</h6> <br/>
                <p>•	Consultation appointments can be rescheduled up to 24 hours before the scheduled time.</p>
                <p>•	Cancellations made within 24 hours of the appointment are non-refundable.</p><br/>

                <h6>2. Refund Policy</h6> <br/>

                <h6>2.1 Digital Products (Reports, Readings, and Forecasts)</h6> <br/>
                <p>•	Due to the nature of our services, we do not offer refunds once the digital product has been delivered.</p>
                <p>•	If you experience technical issues in accessing the product, please contact support for assistance.</p> <br/>

                <h6>2.2 Subscription Refunds</h6>   <br/>

                <p>•	Refunds for subscriptions are only provided in cases of accidental duplicate charges or technical billing errors.</p>
                <p>•	Users must request a refund within 7 days of the transaction by contacting support with proof of payment.</p> <br/>

                <h6>2.3 Live Consultation Refunds</h6> <br/>
                <p>•	Refunds are only available if:</p> 
                <p>o	The astrologer fails to attend the scheduled session.</p> 
                <p>o	There is a technical issue on our end preventing the consultation.</p> 
                <p>•	If the user misses the appointment, no refund will be provided.</p>   <br/>

                <h6>3. Refund Request Process</h6>
                <p>To request a refund, follow these steps:</p> <br/>

                <p>1.	Email [Support Email] with the subject “Refund Request – [Order ID]”.</p>
                <p>2.	Provide details of your purchase, the issue, and supporting evidence (e.g., screenshots).</p>
                <p>3.	Our support team will review and respond within 3-5 business days.</p> <br/>

                <h6>4. Non-Refundable Cases</h6> <br/>
                <p>•	Dissatisfaction with predictions or outcomes (astrology is a guidance-based service).</p>
                <p>•	Incorrect information provided by the user at the time of booking.</p> 
                <p>•	Delayed responses due to external factors beyond our control.</p> 
                 <br/>

               </div>
              <div className='col-12 col-md-1'></div>

          </div>
      </div>
    </>
  )
}

export default CancellationRefund