import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { getDashboardList, getDashboardReport1, getDashboardReport2, getRashiMasterList, getTimeTypeMasterList, getWeekDaysMasterList } from '../AllComponents/Services/MasterApi';
import { Modal, Form, Button, Row, Spinner } from 'react-bootstrap';
import { Navigate, useNavigate } from 'react-router-dom';

//====================================================
import { useTheme } from '@mui/material/styles';
import DeleteIcon from '@mui/icons-material/Delete';
import Box from '@mui/material/Box';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
// import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
// import Select from '@mui/material/Select';
import Chip from '@mui/material/Chip';
import makeStyles from "@mui/styles/makeStyles";
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Input from '@mui/material/Input';
import { getMuhurtType2List } from '../AllComponents/Services/MuhurtDataEntryApi';
import { Autocomplete, TablePagination, TextField } from '@mui/material';
import { toast, ToastContainer } from 'react-toastify';
import { getReportDataApi } from '../AllComponents/Services/CommonApi';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';

const useStyles = makeStyles({
  caption: {
      padding: 0,
      fontWeight: 500,
      fontSize: "12px"
  },
  toolbar: {

      padding: "0px",
      margin: "0px"
  },
  menuItem: {
      fontSize: "12px",
      padding: "4px"
  },
  displayedRows: {
      fontSize: "12px",
      padding: "0px",
      margin: "-15px"
  },

  selectLabel: {
      fontSize: "12px",
      padding: "0px",
      margin: "0px -4px 0px 6px "
  },
  spacer: {
      padding: "0px",
      margin: "0px",
      flex: 'none'
  },
  input: {
      fontSize: "5px",
      padding: "0px",
      margin: "0px"
  },
  root: {
      padding: "0px",
      margin: "0px"
  },

  deleteIcon: {
    '&:hover': {
      color: 'red',
    },
  },

});
//===========================================================================================


const Dashboard = () => {

  
    const navigate = useNavigate();
    const data1=sessionStorage.getItem('token');
    const [token, setToken]=useState(null)

    useEffect(()=>{
      
      if(data1){
        setToken(data1)
      }
      else{
        setToken(null)
      }
  },[])

  useEffect(() => {
    let token=sessionStorage.getItem("token");
    let userdata=sessionStorage.getItem("techno-muhrta");

    if(token==null || userdata==null){
      sessionStorage.clear()
      navigate("/")
    }
  }, [])
//==========================get data code===============================
const [loading, setLoading] = useState(false);
const [open, setOpen] = useState(false);

    const [data, setData] = useState([]);
    const [tableData1, setTableData1] = useState([]);
    const [tableData2, setTableData2] = useState([]);
    const [rashiList, setRashiList] = useState([]);
    const [weekdayList, setWeekdayList] = useState([]);
    const [timeList, setTimeList] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [selectedMuhurtId, setSelectedMuhurtId] = useState('');
    const [muhurtName, setMuhurtName] = useState('');
    const [muhurtType, setMuhurtType] = useState('');
    const [searchTerm1, setSearchTerm1] = useState("")
    const [searchTerm2, setSearchTerm2] = useState("")
    const [muhurtId, setMuhurtId] = useState("")
    const [sortedBy, setSortedBy] = useState(null);
    const [page, setPage] = useState(0)
    const classes = useStyles();
    const [rowsPerPage, setRowsPerPage] = useState(10)
    const [showExtraInfoModal,setShowExtraInfoModal] =useState(false);
    const [isAscending, setAscending] = useState(false);
    
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [Rashi, setRashi] = useState(null);
  const [weekday, setWeekday] = useState(null);
  const [time, setTime] = useState(null);
  const [selectedRadio, setSelectedRadio] = useState("2");
  const [pdfUrl, setPdfUrl] = useState(null);


  const [filteredData1, setFilteredData1] = useState(tableData1)
  const [filteredData2, setFilteredData2] = useState(tableData2)

//=================================================================================


  const handleClose =()=>{
    setShowExtraInfoModal(false);
    setStartDate();
    setEndDate();
    setRashi();
    setWeekday();
    setTime();
    setTableData1([]);
    setTableData2([]);
  }

  const onPageChange = (event, nextPage) => {
    setPage(nextPage);
  }
  const onRowsPerPageChange = (e) => {
    setRowsPerPage(parseInt(e.target.value));
    setPage(0);
  }

  //==============get data code of Rashilist========

  const getRashiData = () =>{

    axios
    .get(getRashiMasterList, {
        headers: {
            'token':data1
          }
    })
    .then((res) => {
        setRashiList(res.data);
         if(res.data == 'UNAUTHORIZED'){
          toast.warn('Your data is UNAUTHORIZED!!', {
            position: 'top-right',
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
          sessionStorage.clear();
          navigate("/"); 
        }
    })
    .catch((err) => console.log(err))
  }
  useEffect(() =>{
    getRashiData();
  },[])
  
   //==============get data code of weekday list========

   const getWeekdayData = () =>{

    axios
    .get(getWeekDaysMasterList, {
        headers: {
            'token':data1
          }
    })
    .then((res) => {
        setWeekdayList(res.data);
        if(res.data == 'UNAUTHORIZED'){
          toast.warn('Your data is UNAUTHORIZED!!', {
            position: 'top-right',
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
          sessionStorage.clear();
          navigate("/"); 
        }
    })
    .catch((err) => console.log(err))
  }
  useEffect(() =>{
    getWeekdayData()
  },[])
  

   //==============get data code of Time List========

   const getTimeData = () =>{

    axios
    .get(getTimeTypeMasterList, {
        headers: {
            'token':data1
          }
    })
    .then((res) => {
        setTimeList(res.data);
        if(res.data == 'UNAUTHORIZED'){
          toast.warn('Your data is UNAUTHORIZED!!', {
            position: 'top-right',
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
          sessionStorage.clear();
          navigate("/"); 
        }
    })
    .catch((err) => console.log(err))
  }
  useEffect(() =>{
    getTimeData()
  },[])
  

  //==============get data code of muhurtlist========

    const filteredData = data.filter( item =>   
        item.muhurtName.toString().toLowerCase().includes(searchQuery.toLowerCase())
    );

    useEffect(() => {

        axios
        .get(getDashboardList, {
            headers: {
                'token':data1
              }
        })
            .then(response => {
                setData(response.data);
                if(response.data == 'UNAUTHORIZED'){
                  toast.warn('Your data is UNAUTHORIZED!!', {
                    position: 'top-right',
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                  });
                  sessionStorage.clear();
                  navigate("/"); 
                }
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
    }, []);

//=========================ExtraInfo code================================

const handleExtraInfo = (item) => {
 
    setSelectedMuhurtId(item.muhurtId)
    setMuhurtName(item.muhurtName)
    setMuhurtType(item.muhurtType)
    setShowExtraInfoModal(true);
  };

  const handleCloseModal = () => {
    setShowExtraInfoModal(false);
    setStartDate();
    setEndDate();
    setRashi();
    setWeekday();
    setTime();
    setTableData1([]);
  };


  const handleSubmit = async (e) => {

    e.preventDefault();
    const formattedWeekday = (weekday || []).map(option => option.id).join(',');
    const formattedRashi = (Rashi || []).map(option => option.id).join(',');
    const formattedTime = (time || []).map(option => option.id).join(',');

    const weekdayValue = formattedWeekday || 0;
    const RashiValue = formattedRashi || 0;
    const timeValue = formattedTime || 0;
    const startDateValue = startDate || 0;
    const endDateValue = endDate || 0;
    const selectedRadioValue = selectedRadio;

    const showDataForMuhurt="1";


    try {
      if (muhurtType == "1") {
        const response = await axios.get(`${getDashboardReport1}?weekDay=${weekdayValue}&rashi=${RashiValue}&time=${timeValue}&fromDate=${startDateValue}&toDate=${endDateValue}&beneficialToRashi=${selectedRadioValue}&showData=${showDataForMuhurt}&muhurt=${selectedMuhurtId}`,
          {
            headers: {
              token: data1
            }
          }
        );
  
        if(response.data === "NOTFOUND"){
          toast.warn('Data Not Found...!!', {
            position: 'top-right',
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
        }

        setTableData1(response.data);
        setFilteredData1(response.data);

      } 
      else if (muhurtType === 2) {

      
        const response = await axios.get(
          
          `${getDashboardReport2}?weekDay=${weekdayValue}&rashi=${RashiValue}&time=${timeValue}&fromDate=${startDateValue}&toDate=${endDateValue}&beneficialToRashi=${selectedRadioValue}&showData=${showDataForMuhurt}&muhurt=${selectedMuhurtId}`,
          {
            headers: {
              token: data1 
            }
          }
          
        );
 
        if(response.data == "NOTFOUND"){
          toast.warn('Data Not Found...!!', {
            position: 'top-right',
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
        }

       
        setTableData2(response.data);
        setFilteredData2(response.data);

        
        
      } 
    } catch (error) {
      console.error('Error fetching data:', error);
    }

  };

    //===================CSS===================
    const cardBack1 = {
        backgroundColor: "#facc6b",
        backgroundImage: "linear-gradient(315deg, #facc6b 0%, #fabc3c 74%)",
        borderRadius: "2rem",
        minHeight: "100px",
        boxShadow: "0px 5px 10px grey",
        color: "#632A17",
        padding: "8px"
    }
    const cardBack2 = {
        backgroundColor: "#2a2a72",
        backgroundImage: "linear-gradient(315deg, #2a2a72 0%, #009ffd 74%)",
        borderRadius: "2rem",
        minHeight: "100px",
        boxShadow: "0px 5px 10px grey",
        color: "#fff",
        padding: "8px"
    }
    const cardFront1 = {
        display: "block",
        border: "2px solid #E3A346",
        borderRadius: "2rem",
        height: "110px",
        padding: "4px",
    }

    const cardFront2 = {
        display: "block",
        border: "2px solid #54C1EA",
        borderRadius: "2rem",
        height: "110px",
        padding: "4px",
    }
    const cardButton1 = {
        backgroundColor: "#a55c1b",
        backgroundImage: "linear-gradient(315deg, #a55c1b 0%, #000000 74%)",
        color: "#fff",
        fontSize: "12px",
        fontWeight: "500",
        cursor: "pointer",
        paddingTop: "6px"
    }

    const cardButton2 = {
        backgroundColor: "#000000",
        backgroundImage: "linear-gradient(147deg, #000000 0%, #04619f 74%)",
        color: "#fff",
        fontSize: "12px",
        fontWeight: "500",
        cursor: "pointer",
        paddingTop: "6px"
    }

    const getCardBack = (index) => {
        return index % 2 === 0 ? cardBack1 : cardBack2;
    };

    const getCardFront = (index) => {
        return index % 2 === 0 ? cardFront1 : cardFront2;
    };

    const getCardButton = (index) => {
        return index % 2 === 0 ? cardButton1 : cardButton2;
    };

    const modalbg = {
        background: "#c31432",
        background: "-webkit-linear-gradient(to right, #240b36, #c31432)",
        background: "linear-gradient(to right, #240b36, #c31432)",
        boxShadow: "0 2px 4px -2px rgba(0,0,0,.5)",
        border:"1px solid grey",color:"white"
      }

//===========================================================================================================
//===========================================================
const TableHeadingTable1 = [
  
  { label: 'अनु. क्र.', key: 'id'},
  { label: 'दिनांक', key: 'date'},
  { label: 'वार', key: 'day'},
  { label: 'राशी', key: 'rashi'},
  { label: 'वेळ टाईप', key: 'timeType'},
  { label: 'वेळ', key: 'time'},
]
const TableHeadingTable2 = [
  
  { label: 'अनु. क्र.', key: 'id', rowspan: 2 },
  { label: 'दिनांक', key: 'date',rowspan: 2},
  { label: 'वार', key: 'day',rowspan: 2 },
  { label: 'पासून', key: 'fromTime', colspan: 2},
  { label: 'पर्यंत', key: 'toTime',colspan: 2 },
]

//===========filter for table 1=========================
  const onSort1 = (key, isAction) => {
      if (!isAction) {
          setSortedBy(key)
          if (isAscending) setFilteredData1([...tableData1.sort((a, b) => a[key] < b[key] ? 1 : -1)])
          else setFilteredData1([...tableData1.sort((a, b) => a[key] > b[key] ? 1 : -1)])
          setAscending(!isAscending)
      }
  }
  useEffect(() => {
    if (Array.isArray(tableData1)) {
      setFilteredData1(
        tableData1.filter(
              (item) =>
                  item.time.toString().toLowerCase().includes(searchTerm1.toLowerCase()) ||
                  item.weekDayName.toString().toLowerCase().includes(searchTerm1.toLowerCase()) ||
                  item.weekDayName.toString().toLowerCase().includes(searchTerm1.toLowerCase()) ||
                  item.timeTypeName.toString().toLowerCase().includes(searchTerm1.toLowerCase()) ||
                  item.rashiName.toString().toLowerCase().includes(searchTerm1.toLowerCase()) ||
                  item.date.toString().toLowerCase().includes(searchTerm1.toLowerCase())
          )
      );
    }
  }, [searchTerm1,tableData1]);

  //===========filter for table 2=========================
  const onSort2 = (key, isAction) => {
    if (!isAction) {
        setSortedBy(key)
        if (isAscending) setFilteredData2([...tableData2.sort((a, b) => a[key] < b[key] ? 1 : -1)])
        else setFilteredData2([...tableData2.sort((a, b) => a[key] > b[key] ? 1 : -1)])
        setAscending(!isAscending)
    }
}

useEffect(() => {
  if (Array.isArray(tableData2)) {
      setFilteredData2(
          tableData2.filter(
              (item) =>
                  item.fromTimeTypeName.toString().toLowerCase().includes(searchTerm2.toLowerCase()) ||
                  item.miti1Name.toString().toLowerCase().includes(searchTerm2.toLowerCase()) ||
                  item.miti2Name.toString().toLowerCase().includes(searchTerm2.toLowerCase()) ||
                  item.muhurtName.toString().toLowerCase().includes(searchTerm2.toLowerCase()) ||
                  item.rashiName.toString().toLowerCase().includes(searchTerm2.toLowerCase()) ||
                  item.weekDayName.toString().toLowerCase().includes(searchTerm2.toLowerCase())
          )
      );
  }
}, [searchTerm2, tableData2]);



//=================pdf data code=======================================================

const generatePDF = async (e) => {
  e.preventDefault();
  setLoading(true);

  const formattedWeekday = weekday ? weekday.map(option => option.id).join(',') : '';
  const formattedRashi = Rashi ? Rashi.map(option => option.id).join(',') : '';
  const formattedTime = time ? time.map(option => option.id).join(',') : '';
  

  const weekdayValue = formattedWeekday || 0;
  const RashiValue = formattedRashi || 0;
  const timeValue = formattedTime || 0;
  const startDateValue = startDate || 0;
  const endDateValue = endDate || 0;
  const selectedRadioValue = selectedRadio || 0;

  const showDataForMuhurt=2;

  try {
    if (muhurtType === 1) {
      const response = await axios.get(
        `${getDashboardReport1}?weekDay=${weekdayValue}&rashi=${RashiValue}&time=${timeValue}&fromDate=${startDateValue}&toDate=${endDateValue}&beneficialToRashi=${selectedRadioValue}&showData=${showDataForMuhurt}&muhurt=${selectedMuhurtId}`,
        {
          headers: {
            token: data1
          }
        }
      );

      const pdfFileName = response.data

      if(response.data === "NOTFOUND"){
        toast.warn('Data Not Found...!!', {
          position: 'top-right',
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      }

      else if (pdfFileName) {
          const pdfUrl = getReportDataApi + `output/${pdfFileName}`

          window.open(pdfUrl, '_blank')

          setPdfUrl(pdfUrl)

      } else if(response.data == 'UNAUTHORIZED'){
        toast.warn('Your data is UNAUTHORIZED!!', {
          position: 'top-right',
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
        sessionStorage.clear();
        navigate("/"); 
      }  else {
          console.error('Invalid PDF URL')
      }

    } 
    else if (muhurtType === 2) {
      const response = await axios.get(
        `${getDashboardReport2}?weekDay=${weekdayValue}&rashi=${RashiValue}&time=${timeValue}&fromDate=${startDateValue}&toDate=${endDateValue}&beneficialToRashi=${selectedRadioValue}&showData=${showDataForMuhurt}&muhurt=${selectedMuhurtId}`,
        null,
        {
          headers: {
            token: data1
          }
        }
      );

      const pdfFileName = response.data
      if(response.data === "NOTFOUND"){
        toast.warn('Data Not Found...!!', {
          position: 'top-right',
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      }

     else if (pdfFileName) {
          const pdfUrl = getReportDataApi + `output/${pdfFileName}`

          window.open(pdfUrl, '_blank')

          setPdfUrl(pdfUrl)

      } else if(response.data == 'UNAUTHORIZED'){
        toast.warn('Your data is UNAUTHORIZED!!', {
          position: 'top-right',
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
        sessionStorage.clear();
        navigate("/"); 
      }  else {
          console.error('Invalid PDF URL')
      }

    } 
  } catch (error) {
    console.error('Error fetching data:', error);
  }
  finally {
    setLoading(false); 
  }

};

//===============================================
// const generatePDF = () => {
//   const pdf = new jsPDF();
//   const tables = document.querySelectorAll('.table');

//   tables.forEach((table, index) => {
//     html2canvas(table).then((canvas) => {
//       const imageData = canvas.toDataURL('image/png');
//       pdf.addImage(imageData, 'PNG', 10, 10 + (index * 100), 180, 100);
//       if (index < tables.length - 1) {
//         pdf.addPage();
//       } else {
//         pdf.save('table_data.pdf');
//       }
//     });
//   });
// };

//===============================================
    return (
        <>
            <div className="container my-3" >

                <div className="">
                      <input
                        type="text"
                        className='form-control form-control-sm'
                        placeholder='मुहूर्त प्रकार शोधा...'
                        style={{ fontSize: "14px" }}
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                       />
                </div>

                <hr />

                <div className="row">
                    {filteredData.map((item,index) => (
                        <div className="col-sm-6 col-md-4 col-lg-3 my-2" key={item.id}>
                                <div style={getCardBack(index)}>
                                <div style={getCardFront(index)}>
                                    <div style={{ borderRadius: "2rem", borderBottom: "2px solid #FFE2B8", padding: "4px 0 0 0", boxShadow: index%2 === 0 ? "0px 4px 8px #E3A346" : "none" }}>
                                        <span style={{ fontWeight: "600", width: "40%", height: "50px", display: "inline-block", fontSize: "30px", textAlign: "center" }}>{item.count}</span>
                                        <span style={{ fontWeight: "600", width: "60%", height: "50px", display: "inline-block", fontSize: "16px", textAlign: "center" }}>{item.muhurtName}</span>
                                    </div>

                                    <div style={{ fontSize: "12px", textAlign: "center", marginTop: "6px", fontWeight: "500" }}>
                                        <span className="badge rounded-pill" style={getCardButton(index)} onClick={() => handleExtraInfo(item)}>सविस्तर माहिती</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>



         {/* Modal for ExtraInfo */}
      <Modal show={showExtraInfoModal} size="xl">
        <Modal.Header  style={modalbg}>
          <Modal.Title> <span className="vr p2" style={{ backgroundColor: '#790B39', width: '5px', opacity: '70%', fontSize: '25px', }}> </span> {muhurtName}</Modal.Title>
            <button className="close" onClick={handleCloseModal} style={{ color: 'white',backgroundColor:"transparent",padding:"0px",border:"none",fontSize:"30px" }}>
                <span aria-hidden="true">&times;</span>
            </button>
        </Modal.Header>
        <Modal.Body style={{backgroundColor:"black",border:"1px solid grey",paddingBottom:"40px"}}>
          <Form>
                <div className="row m-0 ">
                    <div className="col-12 col-md-1"></div>
                    <div className="col-12 col-md-5 pt-2 mt-md-0">
                        <Form.Group controlId="startDate">
                            <Form.Label style={{color:"white"}}>From Date</Form.Label>
                            <Form.Control type="date" style={{borderRadius:"0px"}} required value={startDate}  onChange={(e) => setStartDate(e.target.value)} />
                        </Form.Group>
                    </div>

                    <div className="col-12 col-md-5 pt-2 mt-md-0">
                        <Form.Group controlId="endDate">
                            <Form.Label style={{color:"white"}}>To Date</Form.Label>
                            <Form.Control type="date" style={{borderRadius:"0px"}} required value={endDate} onChange={(e) => setEndDate(e.target.value)} />
                        </Form.Group>
                    </div>
                    <div className="col-12 col-md-1"></div>
                </div>

                <div className="row m-0 ">
                    <div className="col-12 col-md-1"></div>

                    <div className="col-12 col-md-5 pt-2 mt-md-0">
                    <InputLabel style={{ color: 'white',fontSize:"18px" }}>वार</InputLabel>
                      <FormControl fullWidth>
                        
                        
                        <Autocomplete size='small'
                            multiple style={{ color: "black",backgroundColor:"white" }}
                            id="movie-tags"
                            value={weekday || []}
                            onChange={(event, newValue) => {
                                if (newValue.length <= 7) {
                                    setWeekday(newValue);
                                } else {
                                    toast.warn('Please select only three options!', {
                                        position: "top-right",
                                        autoClose: 3000,
                                        hideProgressBar: false,
                                        closeOnClick: true,
                                        pauseOnHover: true,
                                        draggable: true,
                                        theme: 'colored'
                                    });
                                }
                            }}
                            required
                            options={weekdayList}
                            getOptionLabel={(option) => option.weekDay}
                            renderTags={(tagValue, getTagProps) =>
                                tagValue.map((option, index) => (
                                    <Chip
                                        style={{ color: "black" }}
                                        label={option.weekDay}
                                        {...getTagProps({ index })}
                                    />
                                ))
                            }
                            renderInput={(params) => (
                                <TextField {...params} placeholder="वार निवडा"  />
                            )}
                        />





                      </FormControl>
                    </div>

                      <div className="col-12 col-md-5 pt-2 mt-md-0">
                        <InputLabel style={{ color: 'white', fontSize: "18px" }}>राशी</InputLabel>
                        <FormControl fullWidth>


                          <Autocomplete
                              multiple size='small'
                              style={{ color: "black", backgroundColor: "white" }}
                              id="rashi-tags"
                              value={Rashi || []}
                              onChange={(event, newValue) => {
                                  setRashi(newValue);
                              }}
                              options={rashiList}
                              getOptionLabel={(option) => option.rashiName}
                              renderTags={(tagValue, getTagProps) =>
                                  tagValue.map((option, index) => (
                                      <Chip
                                          style={{ color: "black" }}
                                          label={option.rashiName}
                                          {...getTagProps({ index })}
                                      />
                                  ))
                              }
                              renderInput={(params) => (
                                  <TextField {...params} placeholder="राशी निवडा" />
                              )}
                          />


                        </FormControl>
                      </div>

                    <div className="col-12 col-md-1"></div>
                </div>

                <div className="row m-0 ">
                    <div className="col-12 col-md-1"></div>

                      <div className="col-12 col-md-5 pt-2 mt-md-0">
                        <InputLabel style={{ color: 'white', fontSize: "18px" }}>वेळ</InputLabel>
                        <FormControl fullWidth>
                         

                          <Autocomplete
                              multiple size='small'
                              style={{ backgroundColor: "white" }}
                              id="time-tags"
                              value={time || []}
                              onChange={(event, newValue) => {
                                  setTime(newValue);
                              }}
                              options={timeList}
                              getOptionLabel={(option) => option.timeType}
                              renderTags={(tagValue, getTagProps) =>
                                  tagValue.map((option, index) => (
                                      <Chip
                                          label={option.timeType}
                                          {...getTagProps({ index })}
                                      />
                                  ))
                              }
                              renderInput={(params) => (
                                  <TextField {...params} placeholder="समय निवडा" />
                              )}
                          />


                        </FormControl>
                      </div>

                      <div className="col-12 col-md-5 pt-2 mt-md-0">
                          <Form.Group controlId="rashi">
                            <Form.Label style={{ color: 'white' }}>राशी ला लाभणारे</Form.Label>
                            <div className="row m-0" required style={{ height: "38px",  display: "flex", alignItems: "center", backgroundColor: "black" }}>
                              <div className="col-2 text-center d-flex">
                                <Form.Check
                                  type="radio"
                                  aria-label="radio 1"
                                  checked={selectedRadio === '1'}
                                  onChange={() => setSelectedRadio('1')}
                                /> &nbsp; &nbsp; <span style={{ color: "white" }}>होय</span>
                              </div>
                              <div className="col-2 text-center d-flex">
                                <Form.Check
                                  type="radio"
                                  aria-label="radio 2"
                                  checked={selectedRadio === '2'}
                                  onChange={() => setSelectedRadio('2')}
                                /> &nbsp; &nbsp; <span style={{ color: "white" }}>नाही</span>
                              </div>
                            </div>
                          </Form.Group>
                        </div>

                    <div className="col-12 col-md-1"></div>
                </div>
        
                <div className="col-12" style={{display:"flex",justifyContent:"center",alignItems:"center",marginTop:"30px",}}>
                    <Button variant="warning" size="md" type='submit' onClick={handleSubmit}>
                        Show
                    </Button>
                </div> 

          </Form>

{/*=================== Display the table 1==================== */}
       {muhurtType === 1 && 
          <div className="row mt-5 pt-3" style={{backgroundColor:"white"}}>
                      <div className='row'>
                            <div >
                                <input
                                  className='form-control form-control-sm'
                                  type="text"
                                  style={{ width: "250px", display: "block", float: "right", marginBottom: "0px", border: "1px solid #C2C1C1", fontSize: "12px" }}
                                  placeholder="Search Here"
                                  value={searchTerm1}
                                  onChange={(event) => { setSearchTerm1(event.target.value); }}
                              />
                          </div>
                      </div>

                  <div className="table-responsive mt-2">

                  <table className="table table-bordered">
                      <thead className="table-Default">
                          <tr style={{ backgroundColor: "#EDC3FF", backgroundImage: "linear-gradient(200deg, #EDC3FF 10%, #aacaef 100%)" }}>
                              {TableHeadingTable1.map((item, ind) => {
                                  return (
                                      <th
                                          style={{ fontSize: "14px", fontWeight: "600", borderBottom: "2px solid #D8EDF7",}}
                                          onClick={() => onSort1(item.key, item.isAction)}
                                          key={ind}
                                          scope="col"
                                      >
                                          {`${item.label} ${sortedBy === item.key ? isAscending ? '↑' : '↓' : ''}`}
                                      </th>
                                  )
                              })}
                          </tr>
                      </thead>
                      <tbody style={{ borderBottom: "2px solid #D8EDF7",backgroundColor:"white" }}>
          
                                  {Array.isArray(filteredData1) && filteredData1.length > 0 ? (
                                      filteredData1.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                          .map((item, index) => (
                                      <tr key={index}>
                                          <td style={{ fontSize: "15px", fontWeight: "500", }}>{index+1}</td>
                                          <td style={{ fontSize: "15px", fontWeight: "500", }}>{item.date}</td>
                                          <td style={{ fontSize: "15px", fontWeight: "500", }}>{item.weekDayName}</td>
                                          <td style={{ fontSize: "15px", fontWeight: "500", }}>{item.rashiName}</td>
                                          <td style={{ fontSize: "15px", fontWeight: "500", }}>{item.timeTypeName}</td>
                                          <td style={{ fontSize: "15px", fontWeight: "500", }}>{item.time}</td> 
                                        </tr>
                                        ))
                                        ) : (
                                            <tr>
                                                <td colSpan="7">No data found</td>
                                            </tr>
                                        )}
                                    </tbody>


                                      <tfoot >
                                            <tr style={{ padding: "4px",backgroundColor:"white" }}>
                                                <TablePagination
                                                    style={{ padding: 0, borderBottom: "0px" }}
                                                    rowsPerPageOptions={[10, 25, 50, 100]}
                                                    labelRowsPerPage=" Rows : "
                                                    count={filteredData1.length}
                                                    rowsPerPage={rowsPerPage}
                                                    page={page}
                                                    onRowsPerPageChange={onRowsPerPageChange}
                                                    onPageChange={onPageChange}
                                                    classes={{ root: classes.root, input: classes.input, spacer: classes.spacer, selectLabel: classes.selectLabel, toolbar: classes.toolbar, caption: classes.caption, menuItem: classes.menuItem, displayedRows: classes.displayedRows }}
                                                    backIconButtonProps={{ "aria-label": "Previous Page" }}
                                                    nextIconButtonProps={{ "aria-label": "Next Page" }}
                                                />
                                            </tr>
                                        </tfoot>

                  </table>                        
                  </div>
          </div>
        }


{/* =================Display the table 2=============== */}
       {muhurtType === 2 && 
          <div className="row mt-5 pt-3" style={{backgroundColor:"white"}}>
                      <div className='row'>
                            <div >
                                <input
                                  className='form-control form-control-sm'
                                  type="text"
                                  style={{ width: "250px", display: "block", float: "right", marginBottom: "0px", border: "1px solid #C2C1C1", fontSize: "12px" }}
                                  placeholder="Search Here"
                                  value={searchTerm2}
                                  onChange={(event) => { setSearchTerm2(event.target.value); }}
                              />
                          </div>
                      </div>

                  <div className="table-responsive mt-2">

                  <table className="table table-bordered">
                      <thead className="table-Default">
                          <tr style={{ backgroundColor: "#EDC3FF", backgroundImage: "linear-gradient(200deg, #EDC3FF 10%, #aacaef 100%)" }}>
                              {TableHeadingTable2.map((item, ind) => {
                                  return (
                                      <th
                                          style={{ fontSize: "14px", fontWeight: "600", borderBottom: "2px solid #D8EDF7",}}
                                          onClick={() => onSort2(item.key, item.isAction)}
                                          key={ind}
                                          scope="col"
                                          colSpan={item.colspan}
                                          rowSpan={item.rowspan}
                                      >
                                          {`${item.label} ${sortedBy === item.key ? isAscending ? '↑' : '↓' : ''}`}
                                      </th>
                                  )
                              })}
                          </tr>
                          <tr style={{ backgroundColor: "#EDC3FF", backgroundImage: "linear-gradient(200deg, #EDC3FF 10%, #aacaef 100%)" }}>
                          <th style={{ fontSize: "14px", fontWeight: "600", borderBottom: "2px solid #D8EDF7" }}>राशी-1</th>
                          <th style={{ fontSize: "14px", fontWeight: "600", borderBottom: "2px solid #D8EDF7" }}>वेळ-1</th>
                          <th style={{ fontSize: "14px", fontWeight: "600", borderBottom: "2px solid #D8EDF7" }}>राशी-2</th>
                          <th style={{ fontSize: "14px", fontWeight: "600", borderBottom: "2px solid #D8EDF7" }}>वेळ-2</th>
                      </tr>
                      </thead>
                      <tbody style={{ borderBottom: "2px solid #D8EDF7",backgroundColor:"white" }}>

                              {Array.isArray(filteredData2) && filteredData2.length > 0 ? (
                                filteredData2.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    .map((item, index) => (
                                      <tr key={index}>
                                          <td style={{ fontSize: "15px", fontWeight: "500", }}>{index+1}</td>
                                          <td style={{ fontSize: "15px", fontWeight: "500", }}>{item.date}</td>
                                          <td style={{ fontSize: "15px", fontWeight: "500", }}>{item.weekDayName}</td>
                                          <td style={{ fontSize: "15px", fontWeight: "500", }}>{item.fromRashiName}</td>
                                          <td style={{ fontSize: "15px", fontWeight: "500", }}>{item.fromTime}</td>
                                          <td style={{ fontSize: "15px", fontWeight: "500", }}>{item.toRashiName}</td>
                                          <td style={{ fontSize: "15px", fontWeight: "500", }}>{item.toTime}</td>
                                        
                                        </tr>
                                         ))
                                          ) : (
                                              <tr>
                                                  <td colSpan="7">No data found</td>
                                              </tr>
                                          )}
                                      </tbody>


                                        <tfoot >
                                            <tr style={{ padding: "4px",backgroundColor:"white" }}>
                                                <TablePagination
                                                    style={{ padding: 0, borderBottom: "0px" }}
                                                    rowsPerPageOptions={[10, 25, 50, 100]}
                                                    labelRowsPerPage=" Rows : "
                                                    count={filteredData2.length}
                                                    rowsPerPage={rowsPerPage}
                                                    page={page}
                                                    onRowsPerPageChange={onRowsPerPageChange}
                                                    onPageChange={onPageChange}
                                                    classes={{ root: classes.root, input: classes.input, spacer: classes.spacer, selectLabel: classes.selectLabel, toolbar: classes.toolbar, caption: classes.caption, menuItem: classes.menuItem, displayedRows: classes.displayedRows }}
                                                    backIconButtonProps={{ "aria-label": "Previous Page" }}
                                                    nextIconButtonProps={{ "aria-label": "Next Page" }}
                                                />
                                            </tr>
                                        </tfoot>

                  </table>                        
                  </div>
          </div>
        }


          <div className="row m-0 mt-2 text-end">
                    <div className="col-12 col-md-9"></div>
                    <div className="col-12 col-md-2">
                      {/* <button style={{backgroundColor:"orange",padding:"4px 10px",color:"black",border:"1px solid orange",borderRadius:"5px"}} onClick={handlePdfData}>Show Pdf</button> */}

                      <button style={{backgroundColor:"orange",padding:"4px 10px",color:"black",border:"1px solid orange",borderRadius:"5px"}} onClick={generatePDF}  disabled={loading}>{loading ? <Spinner animation='border' size='sm' variant="primary"/> : <b>Show Pdf </b>}</button>

                    </div>
                    <div className="col-12 col-md-1">
                      <button style={{backgroundColor:"transparent",padding:"4px 10px",color:"white",border:"1px solid white",borderRadius:"5px"}} onClick={handleClose}>Close</button>
                    </div>
          </div>

        </Modal.Body>
      </Modal>
   
      <ToastContainer theme='colored' />

            </div>
        </>
    )
}

export default Dashboard