import React, { useState, useEffect } from 'react';
import TitleLabel from '../CommonComponents/TitleLabel';
import TablePagination from '@mui/material/TablePagination'
import { Link, useNavigate } from 'react-router-dom';
import makeStyles from "@mui/styles/makeStyles";
import { useSelector } from "react-redux";
import DriveFolderUploadIcon from '@mui/icons-material/DriveFolderUpload';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import { deletePatrikaEntry, getOnlinePatrikaEntryList, getPatrikaEntryList, uploadFileData } from './Services/PatrikaEntryApi';
import axios from 'axios';
import { getMuhurtType1List } from './Services/MuhurtDataEntryApi';
import { useHistory } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import ArticleIcon from '@mui/icons-material/Article';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import SaveAsIcon from '@mui/icons-material/SaveAs';
import AllInboxIcon from '@mui/icons-material/AllInbox';
import DownloadingIcon from '@mui/icons-material/Downloading';
import { Modal } from 'react-bootstrap';
import PreviewIcon from '@mui/icons-material/Preview';
import { onlinePatrikaPath } from './Services/CommonApi';
import moment from 'moment';


const useStyles = makeStyles({
    caption: {
        padding: 0,
        fontWeight: 500,
        fontSize: "12px"
    },
    toolbar: {

        padding: "0px",
        margin: "0px"
    },
    menuItem: {
        fontSize: "12px",
        padding: "4px"
    },
    displayedRows: {
        fontSize: "12px",
        padding: "0px",
        margin: "-25px"
    },

    selectLabel: {
        fontSize: "12px",
        padding: "0px",
        margin: "0px -4px 0px 6px "
    },
    spacer: {
        padding: "0px",
        margin: "0px",
        flex: 'none'
    },
    input: {
        fontSize: "5px",
        padding: "0px",
        margin: "0px"
    },
    root: {
        padding: "0px",
        margin: "0px"
    }
});

const OnlinePatrikaEntryTable = () => {

    //======================================================================

    const navigate = useNavigate();

    const handleShow = (item) => {
        navigate(`/opf001/`, { state: { data: item, isEditMode: true } });
    };

    //======================================================================

    const titleName = "तयार पत्रिका";

    const [data, setData] = useState([]);
    const classes = useStyles();
    const authUser = useSelector((state) => state.user.value);
    const [isAscending, setAscending] = useState(false);
    const [sortedBy, setSortedBy] = useState(null);
    const [searchTerm, setSearchTerm] = useState("")
    const [page, setPage] = useState(0)
    const [status, setStatus] = useState("")
    const [rowsPerPage, setRowsPerPage] = useState(10)
    const data1 = sessionStorage.getItem('token');




    const onPageChange = (event, nextPage) => {
        setPage(nextPage);
    }
    const onRowsPerPageChange = (e) => {
        setRowsPerPage(parseInt(e.target.value));
        setPage(0);
    }

    const [token, setToken] = useState(null)

    useEffect(() => {

        if (data1) {
            setToken(data1)
        }
        else {
            setToken(null)
        }
    }, [])


    const TableHeading = [
        { label: 'रजी. नं.', key: 'id' },
        { label: `नाव`, key: 'nameMr' },
        { label: `जन्मतारीख`, key: 'bdate' },
        { label: `जन्मवेळ`, key: 'btime' },
        { label: `वार`, key: 'day' },
        { label: `जन्मस्थळ`, key: 'bplace' },
        { label: `मोबाईल नं`, key: 'mob' },
        { label: `रक्कम`, key: 'amt' },
        { label: 'Status', key: 'status', value: 'status', isAction: true },
        { label: 'Show', key: 'show', value: 'show', isAction: true },
        { label: 'Upload', key: 'upload', value: 'upload', isAction: true },
        { label: 'Uploaded File', key: 'view', value: 'view', isAction: true },

    ]


    //=========get data code===============================================
    const getPatrikaEntryData = () => {

        axios
            .get(getOnlinePatrikaEntryList, {
                headers: {
                    'token': data1
                }
            })
            .then((res) => {
                setData(res.data);
                setFilteredData(res.data);
                if (res.data == 'UNAUTHORIZED') {
                    toast.warn('Your data is UNAUTHORIZED!!', {
                        position: 'top-right',
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    });
                    sessionStorage.clear();
                    navigate("/");
                }
            })
            .catch((err) => console.log(err))
    }
    useEffect(() => {
        getPatrikaEntryData()
    }, [])



    const [filteredData, setFilteredData] = useState(data)
    const onSort = (key, isAction) => {
        if (!isAction) {
            setSortedBy(key)
            if (isAscending) setFilteredData([...data.sort((a, b) => a[key] < b[key] ? 1 : -1)])
            else setFilteredData([...data.sort((a, b) => a[key] > b[key] ? 1 : -1)])
            setAscending(!isAscending)
        }
    }
    useEffect(() => {
        setFilteredData(
            data.filter(
                (item) =>
                    (item.name?.toString().toLowerCase().includes(searchTerm.toLowerCase()) || '') ||
                    (item.birthPlace?.toString().toLowerCase().includes(searchTerm.toLowerCase()) || '') ||
                    (item.entryDate?.toString().toLowerCase().includes(searchTerm.toLowerCase()) || '') ||
                    (item.genderName?.toString().toLowerCase().includes(searchTerm.toLowerCase()) || '') ||
                    (item.city?.toString().toLowerCase().includes(searchTerm.toLowerCase()) || '') ||
                    (item.mobileNo?.toString().toLowerCase().includes(searchTerm.toLowerCase()) || '') ||
                    (item.birthDate?.toString().toLowerCase().includes(searchTerm.toLowerCase()) || '') ||
                    (item.status?.toString().toLowerCase().includes(searchTerm.toLowerCase()) || '') ||
                    (item.dayName?.toString().toLowerCase().includes(searchTerm.toLowerCase()) || '')
            )
        );
    }, [searchTerm, data]);


    //=======================

    const handleStatusFilter = (status) => {
        setFilteredData(status ? data.filter((item) => item.status === status) : data);
    };

    //====================================MODAL CODE=======================



    //    // Handle opening the modal
    //    const handleOpenModal = (row) => {
    //     setSelectedRow(row);
    //     setShowModal(true);
    //   };

    //   // Handle closing the modal
    //   const handleCloseModal = () => {
    //     setShowModal(false);
    //     setSelectedRow(null);
    //     setDate("");
    //     setFile(null);
    //   };

    //   // Handle date change
    //   const handleDateChange = (event) => {
    //     setDate(event.target.value);
    //   };

    //   // Handle file change
    //   const handleFileChange = (event) => {
    //     setFile(event.target.files[0]);
    //   };

    //   // Handle form submission
    //   const handleSave = async (e) => {
    //     e.preventDefault();

    //     if (!date || !file || !selectedRow) {
    //       alert("Please fill all the required fields.");
    //       return;
    //     }

    //     const formData = new FormData();
    //     formData.append("name", selectedRow.name);
    //     formData.append("userId", selectedRow.id);
    //     formData.append("date", date);
    //     formData.append("file", file);

    //     try {
    //       const response = await axios.post("YOUR_BACKEND_API_URL", formData, {
    //         headers: {
    //           "Content-Type": "multipart/form-data",
    //         },
    //       });

    //       alert(response.data.message || "Data saved successfully.");
    //       handleCloseModal();
    //     } catch (error) {
    //       console.error("Error saving data:", error);
    //       alert("Failed to save data.");
    //     }
    //   };


    // const [showModal, setShowModal] = useState(false);
    // const [selectedRow, setSelectedRow] = useState(null);
    // const [date, setDate] = useState("");
    // const [file, setFile] = useState(null);

    const [modalOpen, setModalOpen] = useState(false);
    const [selectedUser, setSelectedUser] = useState({});
    const [date, setDate] = useState("");
    const [file, setFile] = useState(null);

    // Handle Modal Open
    const handleUploadClick = (user) => {
        setSelectedUser(user);

        // Get today's date
        const today = new Date();

        // Format as YYYY-MM-DD for the date input
        const isoDate = today.toISOString().split("T")[0];

        // Format as DD/MM/YYYY for display
        const day = String(today.getDate()).padStart(2, '0');
        const month = String(today.getMonth() + 1).padStart(2, '0');
        const year = today.getFullYear();
        const formattedDate = `${day}/${month}/${year}`;

        // Set both dates
        setDate(isoDate); // For input
        // console.log(`Formatted Date (for display): ${formattedDate}`); // For display

        setModalOpen(true);
    };



    // Handle Modal Close
    const handleModalClose = () => {
        setModalOpen(false);
        setSelectedUser({});
        setDate("");
        setFile(null);
    };

    // Handle File Change
    const handleFileChange = (e) => {
        const file = e.target.files[0]; // Get the selected file
        const maxFileSize = 2 * 1024 * 1024; // 3MB in bytes

        if (file) {
            if (file.size > maxFileSize) {
                alert("The file size exceeds the maximum limit of 3 MB. Please select a smaller file.");
                e.target.value = ""; // Reset the file input
                return;
            }
            setFile(file); // Set the valid file to state
        }
    };

    //View Uploaded File
    const handleShowUploadFileClick = async (user) => {
        //fileName 
        // console.log(JSON.stringify(user))
        if (user.fileName == "NOTFOUND") {
            toast.warn("File not found.")
        }
        else {
            window.open(`${onlinePatrikaPath}${user.fileName}`)
        }
    }


    // Save Data
    const handleSave = async () => {
        if (!date || !file) {
            alert("Please fill in all fields and upload a file.");
            return;
        }

        const formData = new FormData();
        //   formData.append("name", selectedUser.name);
        formData.append("regNo", selectedUser.regNo);
        formData.append("createdDate", date);
        formData.append("file", file);

        try {
            const response = await axios.post(uploadFileData, formData, {
                headers: {
                    'token': data1
                }
            });
            if (response.data === "SAVED") {
                toast.success('File uploaded successfully!!', {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                });
                getPatrikaEntryData();
                handleModalClose();
            }

        } catch (error) {
            console.error("Error uploading file:", error);
            alert("Failed to upload file.");
        }
    };


    //=====================================================================


    return (
        <>
            <div className="container">
                <TitleLabel titleName={titleName} />
                <div style={{ backgroundColor: "#fff", padding: "16px", boxShadow: "0px 2px 15px #CECECE", marginTop: "16px", marginBottom: "20px" }}>

                    <div className='row'>
                        <div className="col-sm-8 my-1" style={{ float: "left" }}>
                            <button
                                className="btn saveBtn btn-sm text-light me-2"
                                onClick={() => handleStatusFilter("Pending")}
                            >
                                <DownloadingIcon fontSize="small" /> Pending
                            </button>
                            {/* <button
                                className="btn saveBtn btn-sm text-light me-2"
                                onClick={() => handleStatusFilter("Delivered")}
                            >
                                <AllInboxIcon fontSize="small" /> Delivered
                            </button> */}
                            <button
                                className="btn saveBtn btn-sm text-light me-2"
                                onClick={() => handleStatusFilter("Created")}
                            >
                                <SaveAsIcon fontSize="small" /> Created
                            </button>
                            <button
                                className="btn saveBtn btn-sm text-light me-2"
                                onClick={() => handleStatusFilter("")} // Reset to show all
                            >
                                Show All
                            </button>
                        </div>

                        <div className='col-sm-4 my-1' style={{ float: "right" }} >
                            <input
                                className='form-control form-control-sm'
                                type="text"
                                style={{ width: "250px", display: "block", float: "right", marginBottom: "0px", border: "1px solid #C2C1C1", fontSize: "12px" }}
                                placeholder="Search Here"
                                value={searchTerm}
                                onChange={(event) => { setSearchTerm(event.target.value); }}
                            />
                        </div>
                    </div>
                    <div className="table-responsive mt-2">
                        <table className="table table-bordered">
                            <thead className="table-Default">
                                <tr style={{ backgroundColor: "#EDC3FF", backgroundImage: "linear-gradient(200deg, #EDC3FF 10%, #aacaef 100%)" }}>
                                    {TableHeading.map((item, index) => {
                                        return (
                                            <th
                                                style={{ fontSize: "14px", fontWeight: "600", borderBottom: "2px solid #D8EDF7" }}
                                                onClick={() => onSort(item.key, item.isAction)}
                                                key={index}
                                                scope="col"
                                            >
                                                {`${item.label} ${sortedBy === item.key ? isAscending ? '↑' : '↓' : ''}`}
                                            </th>
                                        )
                                    })}
                                </tr>
                            </thead>

                            <tbody style={{ borderBottom: "2px solid #D8EDF7" }}>
                                {filteredData
                                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    .map((item, index) => (
                                        <tr key={index}>
                                            <td style={{ fontSize: "14px", fontWeight: "500" }}>
                                                {item.regNo}
                                            </td>
                                            <td style={{ fontSize: "14px", fontWeight: "500" }}>
                                                {item.name}
                                            </td>
                                            <td style={{ fontSize: "14px", fontWeight: "500" }}>
                                                {item.birthDate}
                                            </td>
                                            <td style={{ fontSize: "14px", fontWeight: "500" }}>
                                                {item.birthTimeFlag === 1
                                                    ? item.birthTime
                                                    : item.timeFromToFlag === 1
                                                        ? `${item.timeFrom} - ${item.timeTo}`
                                                        : ""}
                                            </td>
                                            <td style={{ fontSize: "14px", fontWeight: "500" }}>
                                                {item.dayName}
                                            </td>
                                            <td style={{ fontSize: "14px", fontWeight: "500" }}>
                                                {item.birthPlace}
                                            </td>
                                            <td style={{ fontSize: "14px", fontWeight: "500" }}>
                                                {item.mobileNo}
                                            </td>
                                            <td style={{ fontSize: "14px", fontWeight: "500" }}>
                                                Rs.{item.amount}
                                            </td>
                                            <td style={{ width: "50px" }}>
                                                <span className="mx-1 p-1">{item.status}</span>
                                            </td>
                                            <td style={{ width: "50px" }}>
                                                <span className="mx-1 p-1">
                                                    <RemoveRedEyeIcon
                                                        fontSize="small"
                                                        style={{ cursor: "pointer", color: "#4A78FF" }}
                                                        onClick={() => handleShow(item)}
                                                    />
                                                </span>
                                            </td>
                                            <td style={{ width: "50px" }}>
                                                <span className="mx-1 p-1">
                                                    <DriveFolderUploadIcon
                                                        fontSize="small"
                                                        style={{ cursor: "pointer", color: "#CD2F44" }}
                                                        onClick={() => handleUploadClick(item)}
                                                    />
                                                </span>
                                            </td>
                                            <td style={{ width: "50px" }}>
                                                <span className="mx-1 p-1">
                                                    <PreviewIcon
                                                        fontSize="small"
                                                        style={{ cursor: "pointer", color: "#d84613" }}
                                                        onClick={() => handleShowUploadFileClick(item)}
                                                    />
                                                </span>
                                            </td>
                                        </tr>
                                    ))}

                                {/* ============Modal for upload file================== */}





                            </tbody>

                            <tfoot >
                                <tr style={{}}>
                                    <TablePagination
                                        style={{ padding: 0, borderBottom: "0px" }}
                                        rowsPerPageOptions={[10, 25, 50, 100]}
                                        labelRowsPerPage=" Rows : "
                                        count={data.length}
                                        rowsPerPage={rowsPerPage}
                                        page={page}
                                        onRowsPerPageChange={onRowsPerPageChange}
                                        onPageChange={onPageChange}
                                        classes={{ root: classes.root, input: classes.input, spacer: classes.spacer, selectLabel: classes.selectLabel, toolbar: classes.toolbar, caption: classes.caption, menuItem: classes.menuItem, displayedRows: classes.displayedRows }}
                                        backIconButtonProps={{ "aria-label": "Previous Page" }}
                                        nextIconButtonProps={{ "aria-label": "Next Page" }}
                                    />
                                </tr>
                            </tfoot>
                        </table>

                        {/* ============Modal for upload file================== */}
                        {modalOpen && (

                            <div className="modal-backdrop" style={{ backgroundColor: "black", }}>

                                <div className='row m-0 mt-5'>
                                    <div className='col-12 col-md-3'></div>
                                    <div className='col-12 col-md-6'>
                                        <div className="modal-content">
                                            <div className="modal-header">
                                                <h5>Upload File</h5>
                                                <button style={{ fontWeight: "bold", border: "none", fontSize: "28px", backgroundColor: "transparent" }} onClick={handleModalClose}>&times;</button>
                                            </div>
                                            <div className="modal-body">
                                                <form>
                                                    <div className="form-group">
                                                        <label>Name</label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            value={selectedUser.name || ""}
                                                            readOnly
                                                        />
                                                    </div>
                                                    <div className="form-group">
                                                        <label>Date</label>
                                                        <input
                                                            type="date"
                                                            className="form-control"
                                                            value={date}
                                                            onChange={(e) => setDate(e.target.value)}
                                                        />
                                                    </div>
                                                    <div className="form-group">
                                                        <label>File</label>
                                                        <input
                                                            type="file"
                                                            className="form-control"
                                                            onChange={handleFileChange}
                                                        />
                                                    </div>
                                                </form>
                                            </div>
                                            <div className="modal-footer">
                                                <button
                                                    className="btn btn-primary"
                                                    onClick={handleSave}
                                                >
                                                    Save
                                                </button>
                                                <button
                                                    className="btn btn-secondary"
                                                    onClick={handleModalClose}
                                                >
                                                    Cancel
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-12 col-md-3'></div>
                                </div>
                            </div>

                        )}
                    </div>
                </div>



            </div>
            <ToastContainer theme='colored' />
        </>
    )
}

export default OnlinePatrikaEntryTable