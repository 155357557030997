import React from 'react'
import logo from '../assets/img/logo.png';
import { useNavigate } from 'react-router-dom';

const PrivacyPolicy = () => {

 const navigate = useNavigate();

  return (
    <>
      <div className='container-fluid p-1 p-lg-5 pt-lg-0' style={{height:"100%", backgroundColor: 'grey'}}>
          <div className='row m-0 '>

              <div className='col-12 text-center'>
                <img src={logo} className="img-fluid" alt="" style={{ pointerEvents: "none", userSelect: "none",width:"30%", }} />
              </div>

              <div className='col-12 px-md-5 text-end'>
                <div className='row m-0  px-lg-5 text-end'>
                  <div className='col-12 py-3 px-lg-4 text-end'>
                    <button className='py-1 px-2 rounded shadow'  onClick={() => navigate('/')} style={{border:"none",backgroundColor:"#bb4495",color:"white",}}>Back To Home</button>
                  </div>
                </div>
              </div>

              <div className='col-12 col-md-1'></div>
              <div className='col-12 col-md-10  p-2 p-md-5 border shadow' style={{backgroundColor:"white"}}>
                <h1 className='text-center'>Privacy Policy</h1>
                <p>
                Welcome to <span className='fw-bold'>Yelavikar Astro!</span> Your privacy is important to us. This Privacy Policy outlines how we collect, use, disclose, and protect your personal information when you use our services.
                </p>

                <h6>1. Information We Collect</h6><br/>
                <p>We may collect the following types of information: </p><br/>

                <h6>1.1 Personal Information</h6> <br/>
                <p>•	Name, email address, phone number</p>
                <p>•	Date of birth, time of birth, and place of birth</p>
                <p>•	Payment details (if applicable)</p> <br/>

                <h6>1.2 Non-Personal Information</h6> <br/>
                <p>•	Device information (IP address, browser type, OS)</p>
                <p>•	App usage statistics and analytics</p>
                <p>•	Cookies and tracking technologies</p><br/>

                <h6>2. How We Use Your Information</h6> <br/>
                <p>We use the collected data to:</p><br/>

                <p>•	Provide personalized astrology readings and reports</p>
                <p>•	Improve user experience and app functionality</p>
                <p>•	Process payments and subscriptions</p>
                <p>•	Send updates, offers, and promotional content (with consent)</p>
                <p>•	Ensure security and prevent fraud</p> <br/>

                <h6>3. Sharing Your Information</h6>   <br/>

                <p>We do not sell, trade, or rent personal data. However, we may share information with:</p> <br/>

                <p>•	Trusted third-party service providers for analytics and payment processing</p>
                <p>•	Legal authorities if required by law</p>
                <p>•	Business partners in case of mergers or acquisitions</p> <br/>

                <h6>4. Data Security</h6> <br/>
                <p>We implement appropriate security measures to protect your data from unauthorized access, loss, or disclosure. However, no system is 100% secure, and we advise users to take necessary precautions.</p> <br/>

                <h6>5. Your Rights</h6>
                <p>You have the right to:</p> <br/>

                <p>•	Access, update, or delete your personal data</p>
                <p>•	Opt-out of marketing communications</p>
                <p>•	Request a copy of the data we hold about you</p> <br/>

                <h6>6. Third-Party Links</h6> <br/>
                <p>Our app may contain links to external websites. We are not responsible for their privacy practices, so we recommend reviewing their policies before sharing personal data.</p> <br/>

                <h6>7. Changes to This Policy</h6> <br/>
                <p>We may update this policy from time to time. Changes will be posted on this page, and continued use of our services implies acceptance.</p> <br/>

                <h6>8. Contact Us</h6> <br/>
                <p>If you have any questions or concerns, please contact us at:</p>
                <p className='fw-bold'> yelavikar@gmail.com</p>
                <p className='fw-bold'> +91 72767 66001</p> <br/>
              </div>
              <div className='col-12 col-md-1'></div>

          </div>
      </div>
    </>
  )
}

export default PrivacyPolicy