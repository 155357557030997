// import React, { useState, useEffect } from 'react';
// import 'react-toastify/dist/ReactToastify.css';
// import 'bootstrap/dist/css/bootstrap.min.css';
// import './App.css';

// import { Route, Routes, useNavigate } from 'react-router-dom';
// import { useDispatch } from 'react-redux';
// import { login } from './reduxFeature/user';

// import Login from './Login';
// import MainPage from './components/MainPage'
// import ContactDetails from './CommonComponents/ContactDetails';
// import TermsCondition from './CommonComponents/TermsCondition';
// import PrivacyPolicy from './CommonComponents/PrivacyPolicy';

// function App() {

//   const navigate = useNavigate();
//   const dispatch = useDispatch();

//   const [isUser, setIsUser] = useState(false);


//   useEffect(() => {

//     const getData = sessionStorage.getItem("techno-muhrta");
//     if (getData) {
//         const localUserData = (JSON.parse(getData));

//         dispatch(login({ roleId: localUserData.roleId, userName: localUserData.userName, }));
//         setIsUser(true);

//     }
// }, []);

// if (!isUser) {
  
//   return (
//     <>
//           <Routes>
//             <Route path="/" element={<Login setIsUser={setIsUser} />} />
//             <Route path="/contactDetails" element={<ContactDetails />} />
//           </Routes>
//     </>
  
    
//   );
// }
// else{

//   return (
//           (!isUser) ? <Login setIsUser={setIsUser} /> : <MainPage setIsUser={setIsUser} />  
    
//   );
// }

// }

// export default App;

//==================================================================================================================
//==================================================================================================================
//==================================================================================================================
//==================================================================================================================

import React, { useState, useEffect } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { login } from './reduxFeature/user';

import 'react-toastify/dist/ReactToastify.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';

import Login from './Login';
import MainPage from './components/MainPage';
import ContactDetails from './CommonComponents/ContactDetails';
import TermsCondition from './CommonComponents/TermsCondition';
import PrivacyPolicy from './CommonComponents/PrivacyPolicy';
import CancellationRefund from './BaseComponents/CancellationRefund';
import ShippingDelivery from './BaseComponents/ShippingDelivery';

function App() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isUser, setIsUser] = useState(false);

  useEffect(() => {
    const userData = sessionStorage.getItem("techno-muhrta");
    if (userData) {
      const parsedUserData = JSON.parse(userData);
      dispatch(
        login({
          roleId: parsedUserData.roleId,
          userName: parsedUserData.userName,
        })
      );
      setIsUser(true);
    }
  }, [dispatch]);

  if (!isUser) {
    return (
      <Routes>
        <Route path="/" element={<Login setIsUser={setIsUser} />} />
        <Route path="/contactDetails" element={<ContactDetails />} />
        <Route path="/privacyPolicy" element={<PrivacyPolicy />} />
        <Route path="/termsCondition" element={<TermsCondition />} />
        <Route path="/cancellationRefund" element={<CancellationRefund />} />
        <Route path="/shippingDelivery" element={<ShippingDelivery />} />
      </Routes>
    );
  }

  return (
    <MainPage setIsUser={setIsUser} />
  );
}

export default App;

//===================================================================================================================================
//===================================================================================================================================
//===================================================================================================================================
//===================================================================================================================================

// import React, { useState, useEffect } from 'react';
// import { Routes, Route, useNavigate } from 'react-router-dom';
// import { HashRouter, BrowserRouter } from 'react-router-dom';
// import { useDispatch } from 'react-redux';
// import { login } from './reduxFeature/user';

// import 'react-toastify/dist/ReactToastify.css';
// import 'bootstrap/dist/css/bootstrap.min.css';
// import './App.css';

// import Login from './Login';
// import MainPage from './components/MainPage';
// import ContactDetails from './CommonComponents/ContactDetails';
// import TermsCondition from './CommonComponents/TermsCondition';
// import PrivacyPolicy from './CommonComponents/PrivacyPolicy';

// function App() {
//   const navigate = useNavigate();
//   const dispatch = useDispatch();
//   const [isUser, setIsUser] = useState(false);

//   useEffect(() => {
//     const userData = sessionStorage.getItem('techno-muhrta');
//     if (userData) {
//       const parsedUserData = JSON.parse(userData);
//       dispatch(
//         login({
//           roleId: parsedUserData.roleId,
//           userName: parsedUserData.userName,
//         })
//       );
//       setIsUser(true);
//     }
//   }, [dispatch]);

//   if (!isUser) {
//     // Use BrowserRouter for public routes
//     return (
//       <BrowserRouter>
//         <Routes>
//           <Route path="/" element={<Login setIsUser={setIsUser} />} />
//           <Route path="/contactDetails" element={<ContactDetails />} />
//           <Route path="/privacyPolicy" element={<PrivacyPolicy />} />
//           <Route path="/termsCondition" element={<TermsCondition />} />
//         </Routes>
//       </BrowserRouter>
//     );
//   }

//   // Use HashRouter for authenticated routes
//   return (
//     <HashRouter>
//       <MainPage setIsUser={setIsUser} />
//     </HashRouter>
//   );
// }

// export default App;


