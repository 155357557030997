import React, { useState, useEffect } from 'react';
import TablePagination from '@mui/material/TablePagination'
import { useLocation, useNavigate } from 'react-router-dom';
import makeStyles from "@mui/styles/makeStyles";
import { useSelector } from "react-redux";
import DriveFolderUploadIcon from '@mui/icons-material/DriveFolderUpload';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import { getGunMilanDataById, getGunMilanDataList, uploadGunMilanFile } from '../Services/GunMilanApi';
import TitleLabel from '../../CommonComponents/TitleLabel';

const useStyles = makeStyles({
    caption: {
        padding: 0,
        fontWeight: 500,
        fontSize: "12px"
    },
    toolbar: {

        padding: "0px",
        margin: "0px"
    },
    menuItem: {
        fontSize: "12px",
        padding: "4px"
    },
    displayedRows: {
        fontSize: "12px",
        padding: "0px",
        margin: "-25px"
    },

    selectLabel: {
        fontSize: "12px",
        padding: "0px",
        margin: "0px -4px 0px 6px "
    },
    spacer: {
        padding: "0px",
        margin: "0px",
        flex: 'none'
    },
    input: {
        fontSize: "5px",
        padding: "0px",
        margin: "0px"
    },
    root: {
        padding: "0px",
        margin: "0px"
    }
});


const GunMilanShowUserData = () => {

  //======================================================================
  const titleName = "गुण मिलन";
  const navigate = useNavigate();
  const classes = useStyles(); 
  
    //====================================================================== 
      const data1=sessionStorage.getItem('token');

      const location = useLocation();
      const initialState = location.state ? location.state.data : {};

       // State for fetched data
  const [userData, setUserData] = useState([]);
  const [loading, setLoading] = useState(true);

   // Extract `id` from `location.state`
   const gunMilanMainId = initialState;

   useEffect(() => {
     if (!gunMilanMainId) {
       toast.error('Invalid ID passed from the previous page.');
       return;
     }

      // Fetch data from the API
    const fetchData = async () => {
      try {
        const token = sessionStorage.getItem('token'); // Assuming token is required for API authentication
        const response = await axios.get(`${getGunMilanDataById}`, {
          params: { id: gunMilanMainId },
          headers: {
            'token':data1
          }
        });
        setUserData(response.data);
      } catch (error) {
        console.error('Error fetching data:', error);
        toast.error('Failed to fetch data.');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [gunMilanMainId]);



      //==============================================
  return (

    <>
             {/* <div className="container">
              <TitleLabel titleName={titleName} />
              <div
                style={{
                  backgroundColor: '#fff',
                  padding: '16px',
                  boxShadow: '0px 2px 15px #CECECE',
                  marginTop: '16px',
                  marginBottom: '20px',
                }}
              >
                {loading ? (
                  <div>Loading...</div>
                ) : (
                  <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                    <thead>
                      <tr>
                        <th style={{ border: '1px solid #ddd', padding: '8px' }}>ID</th>
                        <th style={{ border: '1px solid #ddd', padding: '8px' }}>Name</th>
                        <th style={{ border: '1px solid #ddd', padding: '8px' }}>Birth Date</th>
                        <th style={{ border: '1px solid #ddd', padding: '8px' }}>Birth Time</th>
                        <th style={{ border: '1px solid #ddd', padding: '8px' }}>Birth Place</th>
                      </tr>
                    </thead>
                    <tbody>
                      {userData.map((user) => (
                        <tr key={user.id}>
                          <td style={{ border: '1px solid #ddd', padding: '8px' }}>{user.id}</td>
                          <td style={{ border: '1px solid #ddd', padding: '8px' }}>{user.name}</td>
                          <td style={{ border: '1px solid #ddd', padding: '8px' }}>{user.birthDate}</td>
                          <td style={{ border: '1px solid #ddd', padding: '8px' }}>{user.birthTime}</td>
                          <td style={{ border: '1px solid #ddd', padding: '8px' }}>{user.birthPlace}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                )}
              </div>
            </div> */}

            <div className="container">
        <TitleLabel titleName={titleName} />
        {loading ? (
          <div>Loading...</div>
        ) : (
          <div className={classes.container}>
            {userData.map((user) => (
              <div key={user.id} className={classes.card} >

            <div className='row m-0 mb-3'>
                <div className='col-12 col-md-6 col-lg-4 py-2 rounded' style={{backgroundColor:"#fff"}}>
                  <div className={classes.cardField}>
                    <strong>Name:</strong>{' '}
                    <span className={classes.cardValue}>{user.name}</span>
                  </div>
                  <div className={classes.cardField}>
                    <strong>Birth Date:</strong>{' '}
                    <span className={classes.cardValue}>{user.birthDate}</span>
                  </div>
                  <div className={classes.cardField}>
                    <strong>Birth Time:</strong>{' '}
                    <span className={classes.cardValue}>{user.birthTime}</span>
                  </div>
                  <div className={classes.cardField}>
                    <strong>Birth Place:</strong>{' '}
                    <span className={classes.cardValue}>{user.birthPlace}</span>
                  </div>
                </div>
                <div className='col-12 col-md-6 col-lg-8'></div>
            </div>

              </div>
            ))}
          </div>
        )}
      </div>

          <ToastContainer theme='colored' />
      </>

  )
}

export default GunMilanShowUserData